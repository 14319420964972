import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { socialLinks } from '@rebeldotcom/ui'

const defaultProps = {
  canonical: '',
  description: '',
  imageUrl: '',
  noindex: false,
}

const propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  location: PropTypes.string.isRequired,
  noindex: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

const structuredOrganizationData = site => {
  const sameAs = socialLinks.map(l => l.href)

  const data = {
    '@context': 'http://schema.org',
    '@type': 'Corporation',
    name: site.siteMetadata.shortName,
    description: site.siteMetadata.description,
    image: site.siteMetadata.imageUrl,
    logo: site.siteMetadata.logoUrl,
    url: `https://www.${site.siteMetadata.canonicalHost}`,
    telephone: site.siteMetadata.phone,
    sameAs,
    address: {
      '@type': 'PostalAddress',
      streetAddress: site.siteMetadata.orgStreet,
      addressLocality: site.siteMetadata.orgCity,
      postalCode: site.siteMetadata.orgPostalCode,
      addressCountry: site.siteMetadata.orgCountry,
    },
  }
  return JSON.stringify(data)
}

export function SEO({
  canonical,
  description,
  title,
  imageUrl,
  noindex,
  location,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            canonicalHost
            orgStreet
            orgCity
            orgPostalCode
            orgCountry
            imageUrl
            logoUrl
            twitter
            facebookVerification
          }
        }
      }
    `
  )
  const lang = 'en'

  const canonicalUrl =
    canonical || `https://www.${site.siteMetadata.canonicalHost}${location}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[{ rel: 'canonical', href: canonicalUrl }].concat(
        !canonical
          ? [
              { rel: 'alternate', href: canonicalUrl, hreflang: 'en-ca' },
              { rel: 'alternate', href: canonicalUrl, hreflang: 'x-default' },
            ]
          : []
      )}
      meta={[
        {
          name: 'title',
          content: title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description || site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: imageUrl || site.siteMetadata.imageUrl,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title,
        },
      ]
        .concat(noindex ? [{ name: 'robots', content: 'noindex' }] : [])
        .concat(
          site.siteMetadata.twitter
            ? [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:creator`,
                  content: site.siteMetadata.twitter,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: description || site.siteMetadata.description,
                },
                {
                  property: 'twitter:image:src',
                  content: imageUrl,
                },
              ]
            : []
        )
        .concat(
          site.siteMetadata.facebookVerification
            ? [
                {
                  name: 'facebook-domain-verification',
                  content: site.siteMetadata.facebookVerification,
                },
              ]
            : []
        )}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <script className="structured-data-list" type="application/ld+json">
        {structuredOrganizationData(site)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = defaultProps
SEO.propTypes = propTypes
