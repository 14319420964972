import React, { useEffect, useState } from 'react'
import {
  AlertInline,
  Box,
  Text,
  Button,
  Icon,
  Input,
  PasswordStrengthBar,
  Stack,
  swiftTheme,
  IndeterminateLinearProgress,
  Spinner,
  TableItem,
} from '@rebeldotcom/components'
import styled, { keyframes } from 'styled-components'
import { isEmpty } from 'lodash'
import { MenuButton, isInternic, useOpenExchangeService } from '@rebeldotcom/ui'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { isMobile, isTablet } from 'react-device-detect'
import DiskStorage from './disk-storage'
import STRINGS from './ox.strings'
import OXDeleteRecoverModal from './ox-delete-recover-modal'
import { STATUSES } from '../../../compound/account-status-badge'
import { BASE_URL } from './ox-email-settings'

const { colors } = swiftTheme
const StyledBox = styled(Box)`
  div > div > div[role='menu'] {
    border: 1px solid ${colors.neutral[300]};
  }

  #add-selector > div:nth-child(2) {
    margin-left: 0.2rem;
  }
`

const propTypes = {
  domain: PropTypes.shape({
    contextId: PropTypes.number.isRequired,
    oxAccountId: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  user: PropTypes.shape({
    limitInKB: PropTypes.string,
    oxUserId: PropTypes.number.isRequired,
    usageInKB: PropTypes.string,
    userId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    userStatus: PropTypes.string.isRequired,
  }).isRequired,
}

const gridFormat = {
  gridGap: ['0px 10px'],
  gridTemplateColumns: ['1fr 0.3fr', '1fr 1fr 1fr 0.5fr', '1fr 1fr 1fr 0.5fr'],
  gridMargin: 3,
}

const UrgentPasswordChangeButtonProps = {
  onClick: PropTypes.func.isRequired,
}
const UrgentPasswordChangeButton = ({ onClick }) => {
  return (
    <Stack justifyContent="center" mb={[3, 0]}>
      <Button
        color={isInternic ? 'blueDark' : 'orangePeel'}
        height="50%"
        onClick={onClick}
      >
        <Text py={2} textTransform="uppercase" variant="kiloBold">
          {STRINGS.newPasswordRequired}
        </Text>
      </Button>
    </Stack>
  )
}
UrgentPasswordChangeButton.propTypes = UrgentPasswordChangeButtonProps

const EmailItem = ({ domain, user, index }) => {
  const { navigate, recentlyAdded, setRecentlyAdded, onUserPasswordChange } =
    useOpenExchangeService()
  const [isOpen, setIsOpen] = useState(false)
  const [passwordSuccess, setPWSuccess] = useState(false)
  const [error, setError] = useState()
  const [score, setScore] = useState(0)
  const [disableButton, setDisabled] = useState(true)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [highlightItem, setHighlightItem] = useState(false)
  const [modalAction, setModalAction] = useState({
    action: '',
    contextId: -1,
    userId: -1,
    type: '',
    header: '',
  })

  useEffect(() => {
    if (!isEmpty(recentlyAdded)) {
      setHighlightItem(
        recentlyAdded?.contextId === domain?.contextId &&
          recentlyAdded?.user?.oxUserId === user?.oxUserId
      )
    }
  }, [recentlyAdded])

  const handleSubmit = async ({ password }, resetForm) => {
    setError(null)
    setPasswordLoading(true)
    try {
      const resp = await onUserPasswordChange(
        password,
        domain?.contextId,
        user?.oxUserId
      )

      if (resp) {
        setPWSuccess(true)
        setPasswordLoading(false)
        resetForm()
      } else {
        setError(STRINGS.passwordError)
        setPasswordLoading(false)
        setPWSuccess(false)
      }
    } catch (err) {
      setError(STRINGS.passwordError)
      setPasswordLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: values => {
      handleSubmit({ password: values.password }, formik.resetForm)
    },
  })

  const onChangePassword = e => {
    setPWSuccess(false)
    if (e.target) {
      formik.setFieldValue('password', e.target.value, false)
    }
  }

  useEffect(() => {
    if (score < 3) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [score])

  const editPackageButton = [
    {
      id: 'settings',
      display: 'Settings',
      action: () => {
        navigate(`/my-account/email-manager/${user?.oxUserId}`, {
          state: {
            userId: user?.oxUserId,
            contextId: domain?.contextId,
          },
        })
      },
    },
    {
      id: 'webmail',
      display: 'Webmail',
      action: () => {
        window.open(
          isInternic ? 'https://mail.internic.ca' : 'https://mail.rebel.com'
        )
      },
    },
    {
      id: 'setup-email',
      display: 'Set up email',
      action: () => {
        window.open(`/email/smart-setup?${user?.userName}`)
      },
    },
    {
      id: 'delete-restore-address',
      display: <Text color="red">{STRINGS.deleteEmail}</Text>,
      action: () => {
        setModalAction({
          action: 'delete',
          contextId: domain?.contextId,
          type: 'email',
          userId: user?.oxUserId,
          header: user?.userName,
        })
        setShowModal(true)
      },
    },
  ]

  const onDeleteRecoverModalClose = loading => {
    setShowModal(false)
    setDeleteLoading(loading)
  }

  const onDeleteRecoverModalError = (isAlreadyDeletedFlag = false) => {
    setError(
      isAlreadyDeletedFlag
        ? STRINGS.deleteUser.badRequestError
        : STRINGS.deleteUser.error
    )
    setDeleteLoading(false)
  }

  const onDeleteRecoverModalSuccess = () => {
    setDeleteLoading(false)
  }

  const HighlightBoxKeyframe = keyframes`
  from {
    background-color: ${colors.primary[200]};
  }
  to {
    background-color: ${colors.white};
  }
`
  return (
    <>
      <OXDeleteRecoverModal
        modalAction={modalAction}
        onClose={onDeleteRecoverModalClose}
        onError={onDeleteRecoverModalError}
        onSuccess={onDeleteRecoverModalSuccess}
        show={showModal}
      />
      <>
        <TableItem
          borderBottom={!error ? 'light' : null}
          borderTop={index === 0 ? 'light' : null}
          className={highlightItem ? 'highlight-newly-added' : null}
          css={`
            &.highlight-newly-added {
              background-color: ${colors.white};
              animation-name: ${HighlightBoxKeyframe};
              animation-duration: 1s;
              animation-timing-function: linear;
              animation-iteration-count: 3;
            }
          `} // use the state from the provider
          gridFormat={gridFormat}
          gridGap={gridFormat.gridGap}
          gridTemplateColumns={gridFormat.gridTemplateColumns}
          onAnimationEnd={() => setRecentlyAdded({})} // clear the state from the provider
          px={[1, 1, 2]}
          py={[1, 1, 3]}
        >
          <>
            {isMobile ? (
              <Stack ml={3}>
                <Text my={2}>{`${user?.userName}`}</Text>
                <Box mb={3}>
                  <DiskStorage user={user} />
                </Box>
                {(user.userStatus === STATUSES.PENDINGMIGRATION ||
                  user.userStatus === STATUSES.RESETPASSWORD) && (
                  <UrgentPasswordChangeButton
                    onClick={() => {
                      navigate(`${BASE_URL}/${user.oxUserId}/change-password`, {
                        state: {
                          userId: user?.oxUserId,
                          contextId: domain?.contextId,
                        },
                      })
                    }}
                    user={user}
                  />
                )}
              </Stack>
            ) : (
              <>
                <Stack justifyContent="center">
                  <Text pl={2} py={2}>{`${user?.userName}`}</Text>
                </Stack>
                <DiskStorage user={user} />
              </>
            )}

            {isTablet && (
              <>
                <Box /> <Box />
              </>
            )}

            {(user.userStatus === STATUSES.PENDINGMIGRATION ||
              user.userStatus === STATUSES.RESETPASSWORD) &&
              !isMobile && (
                <Stack justifyContent="center" mb={[3, 0]}>
                  <Button
                    color={isInternic ? 'blueDark' : 'orangePeel'}
                    height="50%"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Text py={2} textTransform="uppercase" variant="kiloBold">
                      {STRINGS.newPasswordRequired}
                    </Text>
                    <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
                  </Button>
                </Stack>
              )}

            {user.userStatus !== STATUSES.PENDINGMIGRATION &&
              user.userStatus !== STATUSES.RESETPASSWORD &&
              !isMobile && (
                <Box
                  alignItems="center"
                  onClick={() => setIsOpen(!isOpen)}
                  style={{ cursor: 'pointer' }}
                >
                  <Text ml={2} mr={1} py={2}>
                    {STRINGS.changePassword}
                  </Text>
                  <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
                </Box>
              )}

            <StyledBox justifyContent="end">
              <MenuButton
                alignment={isMobile ? 'right' : 'left'}
                css={`
                  border: none;
                `}
                id="add-selector"
                label={<Icon name="settings" />}
                options={editPackageButton}
              />
            </StyledBox>
          </>

          {isOpen && !isMobile && (
            <>
              <Text />
              <Text />
              <Stack>
                <Box flexDirection="column" mb={1}>
                  <Box>
                    <Input
                      mr={2}
                      type="password"
                      {...formik.getFieldProps('password')}
                      autoComplete="new-password"
                      id={`new-password-${user.userName}`}
                      onChange={onChangePassword}
                    />
                    <Button
                      color={isInternic ? 'blueDark' : 'deepKoamaru'}
                      disabled={disableButton}
                      onClick={formik.handleSubmit}
                    >
                      {!passwordLoading && !passwordSuccess && 'Save'}
                      {passwordLoading && (
                        <Stack
                          flexDirection="row"
                          justifyContent="center"
                          minWidth="100px"
                          style={{ overflow: 'hidden' }}
                        >
                          <Text mr={2}>{STRINGS.saving}</Text>
                          <Box flexDirection="column">
                            <Spinner
                              color="white"
                              size="1.5rem"
                              stroke=".4rem"
                            />
                          </Box>
                        </Stack>
                      )}
                      {!passwordLoading && passwordSuccess && 'Saved!'}
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <PasswordStrengthBar
                      borderRadius="5px"
                      onChangeScore={s => setScore(s)}
                      password={formik.values.password}
                      showFeedback={!passwordSuccess}
                      showLabels={!passwordSuccess}
                      width="75%"
                    />
                  </Box>
                </Box>
              </Stack>
            </>
          )}
        </TableItem>
        {deleteLoading && (
          <IndeterminateLinearProgress barColor={colors.destructive[100]} />
        )}
        {error && (
          <Stack
            alignSelf="center"
            bg="white"
            borderBottom="light"
            borderLeft="light"
            borderRight="light"
            width="100%"
          >
            <AlertInline
              body={error}
              m={1}
              notificationTitle="error"
              onClose={() => setError(null)}
              variant="error"
            />
          </Stack>
        )}
      </>
    </>
  )
}

EmailItem.propTypes = propTypes
export default EmailItem
