import React, { useState } from 'react'
import {
  Stack,
  Grid,
  Box,
  Heading,
  Text,
  Icon,
  swiftTheme,
  redesignTheme,
  Badge,
  Link,
  AlertInline,
} from '@rebeldotcom/components'
import { isInternic } from '@rebeldotcom/ui'
import PropTypes from 'prop-types'
import STRINGS from '../ox.strings'
import FreeTrialForm from './ox-free-trial-form'
import { FEATURES, BANNER_STRINGS } from './ox-add-package-banner.strings'

const { colors } = swiftTheme
const { fontWeights } = redesignTheme

const gridFormat = {
  gridGap: ['4px'],
  gridTemplateColumns: ['1fr 1fr 1fr'],
  gridMargin: 3,
}

const FeatureItemProps = {
  feature: PropTypes.string.isRequired,
}
const FeatureItem = ({ feature }) => (
  <Box alignItems="center" as="li">
    <Icon
      aria-hidden="true"
      color="white"
      mb={1}
      mr={1}
      name="checkmark-fill"
    />
    <Text variant="pound">{feature}</Text>
  </Box>
)

FeatureItem.propTypes = FeatureItemProps

const FreeTrialBadge = () => (
  <Box display={['none', 'flex', 'flex']} flexDirection="column" width="100%">
    <Badge
      bg="white"
      borderRadius="15px"
      css={`
        white-space: nowrap;
      `}
    >
      <Box height="100%">
        <Text color="#343467" textTransform="none" variant="kiloBold">
          {BANNER_STRINGS.badge}
        </Text>
      </Box>
    </Badge>
  </Box>
)

const OXAddPackageBanner = () => {
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <Stack
      bg="#343467"
      border={`1px solid ${colors.primary[800]}`}
      color="white"
      mb={4}
      mt={2}
      px={3}
      py={2}
      width="100%"
    >
      <Grid
        display={['flex', 'grid', 'grid']}
        flexDirection={['column']}
        gridGap={gridFormat.gridGap}
        gridTemplateColumns={['20% 1fr']}
        justifyContent="space-between"
        my={[0, 2]}
      >
        <Box flexDirection="column" mt={[0, 4, 2]}>
          <Box alignItems="center" mb={2}>
            <Icon
              aria-hidden="true"
              color="white"
              height="5rem"
              mr={2}
              name="cloud-email"
              title="cloud-email-icon"
              width="7rem"
            />
            <Heading lineHeight="20px" variant={['gigaBold', 'teraBold']}>
              {BANNER_STRINGS.logo}
            </Heading>
          </Box>
          <FreeTrialBadge />
        </Box>
        <Box flexDirection="column" ml={[0, 3]}>
          <Heading
            fontSize={['2rem', '2.3rem']}
            fontWeight={fontWeights.bold}
            mb={2}
          >
            {BANNER_STRINGS.heading}
          </Heading>
          <Grid
            as="ul"
            display={['flex', 'grid', 'grid']}
            flexDirection={['column']}
            gridGap={gridFormat.gridGap}
            gridTemplateColumns={gridFormat.gridTemplateColumns}
          >
            {FEATURES.map(f => (
              <FeatureItem key={f} feature={f} />
            ))}
          </Grid>
        </Box>
      </Grid>
      <FreeTrialForm setError={setError} setErrorMessage={setErrorMessage} />
      <Box
        display={['flex', 'none']}
        flexDirection="column"
        justifyContent="flex-end"
        pt={1}
      >
        <Link
          color={isInternic ? null : 'magenta'}
          href="/email/cloud-email/buy?itemCode=CLOUD_SEC"
          mt={[2]}
          variant="solid"
          width={['100%', '20%']}
        >
          <Text display="flex" justifyContent="center" variant="mega">
            <Icon
              aria-hidden="true"
              color="white"
              mr={1}
              name="cloud-email"
              title="start-free-trial"
              width="4rem"
            />
            {BANNER_STRINGS.cta}
          </Text>
        </Link>
        <Box
          display={['flex', 'flex', 'flex']}
          justifyContent="center"
          mt={2}
          width="100%"
        >
          <Text fontSize="1rem">{BANNER_STRINGS.finePrint}</Text>
        </Box>
      </Box>
      {error && (
        <AlertInline
          body={errorMessage}
          mx={3}
          my={3}
          notificationTitle="error"
          variant="error"
        />
      )}
    </Stack>
  )
}

export default OXAddPackageBanner
