import { hasDOM, addToCart } from '@rebeldotcom/ui'

export const containerVariants = {
  light: {
    bg: 'greys.8',
    text: 'greys.1',
  },
  dark: {
    bg: 'greys.1',
    text: 'greys.8',
  },
  white: {
    bg: 'white',
    text: 'greys.1',
  },
}

export const textAlignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export const verticalAlignment = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
}

export const getImageResponse = image => {
  if (!image || !image.breakpoints) return { display: 'block' }
  const { breakpoints: bps } = image
  const display = new Array(bps.sort()[bps.length - 1]).fill('none')
  bps.forEach(bp => {
    display[bp] = 'block'
  })
  return {
    display,
  }
}

export const getImageProps = image => {
  return image
    ? {
        fluid: image.path.childImageSharp.fluid,
      }
    : {}
}

export const getSectionSize = size => {
  switch (size) {
    case 'small':
      return {
        height: '25rem',
      }
    case 'medium':
      return {
        height: ['25rem', '45rem'],
      }
    case 'large':
      return {
        height: ['25rem', '50rem', '65rem'],
      }
    default:
      return {
        height: ['25rem', '40rem'],
      }
  }
}

export const handleClick = (e, href) => {
  if (hasDOM && href && href.includes('#')) {
    e.preventDefault()
    const el = document.querySelector(href)

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      el.focus()
    }
  }
}

export const handleCTAClick = (cta, price, currentCode) => {
  if (cta.type === 'addToCart') {
    let amount = null
    if (
      price.dynamicPrice &&
      price.dynamicPrice.prices &&
      price.dynamicPrice.prices[currentCode.toUpperCase()]
    ) {
      amount = price.dynamicPrice.prices[currentCode.toUpperCase()].regular
    } else if (price[currentCode]) {
      amount = price[currentCode].regular
    }
    addToCart({
      addToCartPath: cta.addToCartPath,
      amount,
      currency: currentCode,
      itemCode: cta.itemCode,
      itemDescription: cta.itemDescription,
      product: cta.product,
      productCategory: cta.productCategory,
      returnPath: cta.returnPath,
      source: cta.source,
    })
  }
}

/**
  Scrolls the slider by one panel width in the specified direction, and loops the slider around if necessary.
  To implement, make a flex container with overflow: scroll, hidden scrollbars, and justify-content: flex-start. 
  Fill it with full-width slider items, each with the className 'slider-item'.
  @param {Object} slider - The DOM node representing the slider container. If using Ref, pass in Ref.current.
  @param {string} scrollDirection - The direction to scroll the slider. '+' scrolls right, '-' scrolls left. It's '+' by default.
  @throws {Error} If the slider component is not found.
  @summary 'Position' variable name refers to the distance in px from the start of the slider to the left side of the panel in question.
*/
export const sliderScroller = (slider, scrollDirection = '+') => {
  if (!slider) throw new Error('Slider component not found')
  if (scrollDirection !== '+' && scrollDirection !== '-')
    throw new Error('Scroll direction must be + or -')

  const singlePanelWidth = slider.offsetWidth
  slider.scrollBy({
    left: scrollDirection + singlePanelWidth,
    behavior: 'smooth',
  })

  // Loops slider around.
  const numItemsInSlider = slider.querySelectorAll('.slider-item').length
  const currentPanelPosition = slider.scrollLeft
  const lastPanelPosition = singlePanelWidth * (numItemsInSlider - 1)
  const isFirstPanel = currentPanelPosition === 0
  const isLastPanel = currentPanelPosition === lastPanelPosition
  const isGoingLeftFromFirstPanel = isFirstPanel && scrollDirection === '-'
  const isGoingRightFromLastPanel = isLastPanel && scrollDirection === '+'
  if (isGoingLeftFromFirstPanel)
    slider.scrollTo({ left: lastPanelPosition, behavior: 'smooth' })
  if (isGoingRightFromLastPanel)
    slider.scrollTo({ left: 0, behavior: 'smooth' })

  // Ensures complete scroll.
  const isPanelNotFullyScrolled = currentPanelPosition % singlePanelWidth !== 0
  const nearestPanelPosition =
    Math.round(currentPanelPosition / singlePanelWidth) * singlePanelWidth
  if (isPanelNotFullyScrolled)
    slider.scrollTo({ left: nearestPanelPosition, behavior: 'smooth' })
}
