/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import {
  AlertInline,
  Box,
  Text,
  Button,
  Input,
  PasswordStrengthBar,
  Stack,
  Spinner,
  TableItem,
} from '@rebeldotcom/components'
import {
  useSessionService,
  Price,
  useOpenExchangeService,
  isInternic,
  tracker,
} from '@rebeldotcom/ui'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import STRINGS, {
  ANALYTICS_EVENTS,
  PRICING_ITEM_CODES,
  USERNAME_REGEX,
} from './ox.strings'
import { PricesQuery } from '../../../../utilities/pricing'

const propTypes = {
  domain: PropTypes.shape({
    actionRequired: PropTypes.bool,
    contextId: PropTypes.number.isRequired,
    domain: PropTypes.string.isRequired,
    oxAccountId: PropTypes.number.isRequired,
    packageId: PropTypes.number,
    itemCode: PropTypes.string,
  }).isRequired,
}

const gridFormat = {
  gridGap: '5px',
  gridTemplateColumns: ['2fr 1fr 0.5fr'],
  gridMargin: 3,
}

const OXAddEmail = ({ domain }) => {
  const {
    data: {
      currentCurrency: { code },
    },
  } = useSessionService()
  const { onNewOX } = useOpenExchangeService()
  const [score, setScore] = useState(0)
  const [disableButton, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { packageId, itemCode } = domain
  const currencyCode = code.toUpperCase()

  const prices = PricesQuery()
  const packagePrice = prices?.find(
    dp => dp.itemCode === PRICING_ITEM_CODES[itemCode]
  )

  if (
    packagePrice &&
    packagePrice.prices &&
    packagePrice.prices[currencyCode] &&
    packagePrice.prices[currencyCode].regular
  ) {
    // For OX, the renew price is the regular price, so do the reassignment
    packagePrice.prices[currencyCode].regular =
      packagePrice?.prices[currencyCode]?.renew
  }
  const displayPrice = Price.getDisplayPrice(packagePrice?.prices, currencyCode)

  /**
   * TD:
   *  Awaiting backend endpoint for when accounts need to be re-provisioned on OX
   *  (accounts w/o ContextId and oxUserId)
   */
  const handleSubmit = async ({ username, password }, resetForm) => {
    setLoading(true)
    setError('')
    const [lastName] = domain?.domain.split('@')

    tracker(ANALYTICS_EVENTS.cloudEmail.event, {
      type: ANALYTICS_EVENTS.cloudEmail.types.addEmail,
      cloudEmail: {
        itemCode,
        email: `${username}@${lastName}`,
        domain: lastName,
        username,
      },
    })

    try {
      // Call goes to UI.
      await onNewOX(
        {
          packageId,
          domainName: domain?.domain,
          username,
          password,
          firstName: username,
          lastName,
        },
        false
      )
      resetForm()
      setLoading(false)
    } catch (err) {
      setError(err?.message)
      setLoading(false)
    }
  }

  const validate = values => {
    const errors = {}

    if (values.username && !USERNAME_REGEX.test(values.username)) {
      errors.username = 'Invalid username'
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      formikPassword: '',
      username: '',
    },
    validate,
    onSubmit: values =>
      handleSubmit(
        { username: values.username, password: values.formikPassword },
        formik.resetForm
      ),
  })

  useEffect(() => {
    if (score < 3 || !formik.values.username) {
      setDisabled(true)
    } else if (score >= 3 && formik.values.username) {
      setDisabled(false)
    }
  }, [score, formik.values.username])

  const onChangePassword = e => {
    if (e.target) {
      formik.setFieldValue('formikPassword', e.target.value, false)
    }
  }

  return (
    <Stack
      alignSelf="center"
      bg="white"
      borderBottom="light"
      borderLeft="light"
      borderRight="light"
      borderTop={domain?.actionRequired ? 'light' : null}
      pb={3}
      width="100%"
    >
      {domain?.actionRequired && (
        <Box>
          <Text display="flex" lineHeight="0" ml={2} mt={4} variant="kiloBold">
            {STRINGS.provisionCreateEmail}
          </Text>
        </Box>
      )}
      <form autoComplete="off">
        <TableItem
          alignItems={['left', 'center']}
          border="none"
          flexDirection={['column', 'row']}
          gridFormat={gridFormat}
          gridGap={gridFormat.gridGap}
          gridTemplateColumns={gridFormat.gridTemplateColumns}
          my={3}
          px={[2]}
          width={['100%', '80%']}
        >
          <Stack
            alignSelf={['center', 'center', 'baseline']}
            mt={[2, 0]}
            width={['90%', '70%', 'auto']}
          >
            <Box>
              <Input
                placeholder="ex. john, info"
                type="text"
                {...formik.getFieldProps('username')}
                autoComplete="off"
                id={`create-email-prefix-${domain?.domain}`}
              />
              <Input
                disabled
                id={`create-email-domain-${domain?.domain}`}
                value={`@${domain?.domain}`}
              />
            </Box>
            {!domain?.actionRequired && (
              <Text display={['none', 'none', 'flex']} variant="kiloBold">
                {STRINGS.addEmailText(displayPrice)}
              </Text>
            )}
          </Stack>
          <Stack
            alignSelf={['center', 'center', 'baseline']}
            mt={[2, 0]}
            // maxWidth={['unset', 'unset', '235px']}
            width={['90%', '70%', 'auto']}
            // mr={[0, 1, 3]}
            // position="relative"
            // top={['unset', '15.1px', '17px']}
          >
            <Input
              id={`create-email-password-${domain.domain}`}
              placeholder="Enter Password"
              type="password"
              {...formik.getFieldProps('formikPassword')}
              autoComplete="new-password"
              onChange={onChangePassword}
            />
            <Box mt={2}>
              <PasswordStrengthBar
                borderRadius="5px"
                onChangeScore={s => setScore(s)}
                password={formik.values.formikPassword}
                showFeedback
                showLabels
              />
            </Box>
          </Stack>
          <Stack
            alignItems="center"
            alignSelf={['center', 'center', 'flex-start']}
            mt={[2, 0]}
            width={['90%', '70%', 'auto']}
          >
            <Button
              color={isInternic ? 'blueDark' : 'deepKoamaru'}
              disabled={disableButton || loading}
              onClick={formik.handleSubmit}
              pb="9px"
              pl={4}
              pr={4}
              variant="solid"
              width="100%"
            >
              {loading && (
                <Stack flexDirection="row" style={{ overflow: 'hidden' }}>
                  <Text mr={2}>{STRINGS.loadingEmailCTA}</Text>
                  <Box alignSelf="start" flexDirection="column">
                    <Spinner color="white" size="1.5rem" stroke=".4rem" />
                  </Box>
                </Stack>
              )}
              {!loading && STRINGS.createEmail(domain?.actionRequired)}
            </Button>
          </Stack>
        </TableItem>
      </form>
      {formik.touched.username && formik.errors.username ? (
        <Text color="red" mb={0} ml={[0, '15px']} variant="milliBold">
          {formik.errors.username}
        </Text>
      ) : null}
      {error && (
        <AlertInline
          body={error}
          mb={3}
          mx={3}
          notificationTitle="error"
          variant="error"
        />
      )}
      {!domain?.actionRequired && (
        <Text
          display={['flex', 'flex', 'none']}
          mb={2}
          ml={3}
          variant="kiloBold"
        >
          {STRINGS.addEmailText(displayPrice)}
        </Text>
      )}
    </Stack>
  )
}

OXAddEmail.propTypes = propTypes

export default OXAddEmail
