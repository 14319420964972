import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Text, Box, Heading, swiftTheme } from '@rebeldotcom/components'
import styled from 'styled-components'
import { isInternic, MenuButton, useOpenExchangeService } from '@rebeldotcom/ui'
import { isMobile } from 'react-device-detect'
import STRINGS from './ox.strings'
import { BASE_URL } from './ox-email-settings'
import OXDeleteRecoverModal from './ox-delete-recover-modal'
import {
  AccountStatusBadge,
  STATUSES,
} from '../../../compound/account-status-badge'
import { isActiveUser } from './ox-email-manager'

const { colors } = swiftTheme
// Add border to the menu items
const StyledBox = styled(Box)`
  div > div > div[role='menu'] {
    border: 1px solid ${colors.neutral[300]};
  }
`
const propTypes = {
  domain: PropTypes.shape({
    accountDeleteDate: PropTypes.string,
    accountStatus: PropTypes.string,
    actionRequired: PropTypes.bool,
    anniversaryDate: PropTypes.string.isRequired,
    contextId: PropTypes.number,
    domain: PropTypes.string,
    itemCode: PropTypes.string,
    oxAccountId: PropTypes.number,
    packageId: PropTypes.number,
    subscriptionStatus: PropTypes.string,
    users: PropTypes.array,
  }).isRequired,
}

const gridFormat = {
  gridGap: ['10px'],
  gridTemplateColumns: ['1fr 1fr 1fr 1fr'],
  gridMargin: 3,
}

const DomainHeader = ({ domain }) => {
  const { navigate } = useOpenExchangeService()
  const [showModal, setShowModal] = useState(false)
  const [modalAction, setModalAction] = useState({
    action: '',
    contextId: -1,
    userId: -1,
    type: '',
    header: '',
  })
  const expiring = domain?.accountStatus === STATUSES.PENDING
  const activeUsers = domain?.users.filter(u => isActiveUser(u))

  const anniversaryDate = new Date(domain?.anniversaryDate).toLocaleDateString(
    'en-US'
  )

  const editPackageOptions = [
    {
      id: 'dns-setup',
      display: 'DNS Setup',
      action: () => {
        navigate(`${BASE_URL}/${domain?.contextId}/dns-setup`, {
          state: {
            contextId: domain?.contextId,
          },
        })
      },
    },
    {
      id: 'dns-settings',
      display: 'DNS Settings',
      action: () => {
        navigate(`${BASE_URL}/${domain?.contextId}/dns`, {
          state: {
            contextId: domain?.contextId,
          },
        })
      },
    },
    {
      id: 'webmail',
      display: 'Webmail',
      action: () => {
        window.open(
          isInternic ? 'https://mail.internic.ca' : 'https://mail.rebel.com'
        )
      },
    },
    {
      id: 'setup-email',
      display: 'Set up email',
      action: () => {
        window.open(`/email/smart-setup`)
      },
    },
    {
      id: 'change-package',
      display: 'Change Package',
      action: () => {
        navigate(`${BASE_URL}/${domain?.contextId}/change-package`, {
          state: {
            contextId: domain?.contextId,
          },
        })
      },
    },
    {
      id: 'delete-restore-package',
      display: (
        <Text color={expiring ? 'black' : 'red'}>
          {expiring ? STRINGS.reactivatePackage : STRINGS.deletePackage}
        </Text>
      ),
      action: () => {
        setModalAction({
          action: expiring ? 'restore' : 'delete',
          contextId: domain?.contextId,
          type: 'package',
          header: domain?.domain,
        })
        setShowModal(true)
      },
    },
  ]

  const onClose = () => {
    setShowModal(false)
  }

  const getAccountStatus = () => {
    if (domain?.actionRequired) return domain?.actionRequired
    if (domain?.subscriptionStatus === STATUSES.SUSPENDED) {
      return domain?.subscriptionStatus
    }

    return domain?.accountStatus
  }

  return (
    <>
      <OXDeleteRecoverModal
        isSynchronous
        modalAction={modalAction}
        onClose={() => onClose(false)}
        show={showModal}
      />
      <Stack>
        {/* Package status badge */}
        <Box
          alignItems={['flex-start', 'end', 'end']}
          display={['flex', 'grid', 'grid']}
          flexDirection="column"
          gridGap={gridFormat.gridGap}
          gridTemplateColumns={gridFormat.gridTemplateColumns}
          mx={1}
          my={3}
          width="99%"
        >
          <Box flexDirection={['column', 'row']} width={['60%', '100%']}>
            <Heading variant="gigaBold">{`${domain?.domain}`}</Heading>
            <AccountStatusBadge status={getAccountStatus()} />
          </Box>

          {domain?.subscriptionStatus !== STATUSES.SUSPENDED && (
            <>
              {/* Displays number of active users within package */}
              <Box
                flexDirection={['column', 'column', 'row']}
                justifyContent={['left', 'center', 'center']}
              >
                <Text>
                  {STRINGS.activeUsers(domain?.itemCode, activeUsers.length)}
                </Text>
              </Box>

              <Box flexDirection="row" justifyContent="end">
                <Text mr={1}>
                  {expiring ? STRINGS.expiry : STRINGS.renewal}
                </Text>
                <Text mr={3} variant="megaBold">
                  {anniversaryDate}
                </Text>
              </Box>

              {/* Dropdown Options Menu for active packages */}
              {!domain?.actionRequired && (
                <StyledBox
                  alignItems="flex-end"
                  id="manager-menu"
                  justifyContent={['flex-start', 'flex-end', 'flex-end']}
                >
                  <MenuButton
                    alignment="left"
                    id="add-selector"
                    label={STRINGS.options}
                    options={editPackageOptions}
                  />
                </StyledBox>
              )}

              {/* Fill in this grid space on desktop only */}
              {!isMobile && <Box />}
            </>
          )}
        </Box>
      </Stack>
    </>
  )
}

DomainHeader.propTypes = propTypes
export default DomainHeader
