import React from 'react'
import PropTypes from 'prop-types'
import {
  AlertInline,
  Box,
  Text,
  Heading,
  Button,
  Stack,
  Section,
  Spinner,
} from '@rebeldotcom/components'
import {
  useSessionService,
  Price,
  useOpenExchangeService,
  put,
  isInternic,
} from '@rebeldotcom/ui'
import STRINGS from '../../my-account/email-manager/ox-email/ox.strings'
import { GetCurrencies } from '../../../utilities/pricing'
import { getResellerBaseURL } from '../../../utilities/endpoints'
import {
  isActiveUser,
  SimpleDivider,
} from '../../my-account/email-manager/ox-email/ox-email-manager'

const propTypes = {
  allDynamicPrices: PropTypes.array.isRequired,
  selectedPackage: PropTypes.shape({
    addToCartPath: PropTypes.string,
    amount: PropTypes.number,
    id: PropTypes.string,
    itemCode: PropTypes.string,
    itemDescription: PropTypes.string,
    packageId: PropTypes.number,
    price: PropTypes.shape({
      itemCode: PropTypes.string,
      suffix: PropTypes.string,
    }),
    product: PropTypes.string,
    productCategory: PropTypes.string,
    returnPath: PropTypes.string,
    source: PropTypes.string,
  }),
}

const defaultProps = {
  selectedPackage: {},
}

const ChangeCloudEmailPackage = ({ selectedPackage, allDynamicPrices }) => {
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()
  const { navigate, currentContext } = useOpenExchangeService()
  const userCount = currentContext?.users?.filter(u => isActiveUser(u)).length

  const [errorMessage, setErrorMessage] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const total = `$${(
    userCount *
    Price.getFloatPrice(
      GetCurrencies(selectedPackage?.price, allDynamicPrices),
      currentCode.toUpperCase()
    )
  ).toFixed(2)}/month`

  const onSubmit = async () => {
    setLoading(true)
    try {
      const { addToCartPath } = selectedPackage

      const changePackageEndpoint = `${getResellerBaseURL()}${addToCartPath.replace(
        '{{domain}}',
        currentContext.domain
      )}`

      await put(changePackageEndpoint, {})
      window.location = '/my-account/email-manager'
      setLoading(false)
    } catch ({ response }) {
      const { packages } = STRINGS
      let errorMessage = response.data.err.message
      // The error message is sent to us from RebelWeb, using the DB itemCodes. This snippet is to map them to their Display Name.
      Object.entries(packages).forEach(([key, value]) => {
        errorMessage = errorMessage.replace(key, value)
      })
      setErrorMessage(errorMessage)
      setLoading(false)
    }
  }

  return (
    <Section
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      pb={[4, 5]}
      pt={[2, 3]}
    >
      <Stack width={['100%', '64%', '54%']}>
        <Box justifyContent="space-between" mb={2} mt={5}>
          <Heading variant="gigaBold">{`Total (${userCount} users)`}</Heading>
          <Text variant="gigaBold">
            {currentCode.toUpperCase()} {total}
          </Text>
        </Box>
        <SimpleDivider />
        <Text my={1}> {STRINGS.changePackageDisclaimer}</Text>
        {errorMessage && (
          <AlertInline
            body={errorMessage}
            mt={3}
            notificationTitle="error"
            variant="error"
          />
        )}
        <Box justifyContent={['center', 'flex-start']}>
          <Button
            color={isInternic ? 'blueDark' : 'deepKoamaru'}
            id="change-package-submit"
            mr={3}
            mt={3}
            onClick={onSubmit}
            py={3}
            width="40%"
          >
            {loading && (
              <Stack flexDirection="row" style={{ overflow: 'hidden' }}>
                <Text mr={2}>{STRINGS.changePackageLoading}</Text>
                <Box alignSelf="start" flexDirection="column">
                  <Spinner color="white" size="1.5rem" stroke=".4rem" />
                </Box>
              </Stack>
            )}
            {!loading && STRINGS.changePackage}
          </Button>
          <Button
            color={isInternic ? 'blueDark' : 'deepKoamaru'}
            id="cancel-upgrade-package"
            mt={3}
            onClick={() => {
              navigate(`/my-account/email-manager`)
            }}
            variant="inverse"
          >
            {STRINGS.cancel}
          </Button>
        </Box>
      </Stack>
    </Section>
  )
}

ChangeCloudEmailPackage.propTypes = propTypes
ChangeCloudEmailPackage.defaultProps = defaultProps
export { ChangeCloudEmailPackage }
