import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { isInternic, post, tracker, RESELLER } from '@rebeldotcom/ui'
import {
  Section,
  Stack,
  Box,
  Text,
  Input,
  redesignTheme,
  Button,
  Spinner,
  PasswordStrengthBar,
  AlertInline,
} from '@rebeldotcom/components'
import { PricesQuery } from '../../../utilities/pricing'
import { Title } from '../../title'
import Image from '../../image'
import { strings } from './ox-purchase-strings'
import ConfiguratorSelector from '../configurator/configurator-selector'
import { getOXBuyEmailEndpoints } from '../../../utilities/endpoints'
import OXPackages from '../../my-account/email-manager/ox-email/ox-packages'
import STRINGS, {
  ANALYTICS_EVENTS,
  PACKAGE_ITEM_CODES,
  USERNAME_REGEX,
} from '../../my-account/email-manager/ox-email/ox.strings'

const { fonts } = redesignTheme
const propTypes = {
  domain: PropTypes.string.isRequired,
  user: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
}

const OXPurchaseConfig = ({ domain }) => {
  const [error, setError] = useState('')
  const [score, setScore] = useState(0)
  const [loading, setLoading] = useState(false)
  const [disableButton, setDisabled] = useState(true)
  const [selectedPackage, setSelectedPackage] = useState()

  useEffect(() => {
    const getUrlParams = () => {
      const searchParams = new URLSearchParams(window.location.search)
      return {
        itemCode: searchParams.get('itemCode'),
      }
    }

    const { itemCode } = getUrlParams()

    setSelectedPackage(
      OXPackages.find(
        resellerPkgs => resellerPkgs.reseller === RESELLER
      ).data.find(pkg => pkg.itemCode === itemCode).packageId
    )
  }, [])

  const [sectionTitle, setSectionTitle] = useState(
    strings.title(selectedPackage)
  )

  const allDynamicPrices = PricesQuery()

  const query = graphql`
    query {
      file(relativePath: { eq: "workingonlaptop.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

  const data = useStaticQuery(query)

  const handlePackageSelection = packageId => {
    setSelectedPackage(packageId)
  }

  const onEmailSubmit = async values => {
    setLoading(true)
    const payload = {
      packageId: selectedPackage,
      users: 1,
      domainName: domain,
      password: values.formikPassword,
      ...values,
    }

    const [lastname] = domain.split('@')
    payload.firstName = values.username
    payload.lastName = lastname

    tracker(ANALYTICS_EVENTS.cloudEmail.event, {
      type: ANALYTICS_EVENTS.cloudEmail.types.buyPage,
      cloudEmail: {
        itemCode: PACKAGE_ITEM_CODES[selectedPackage],
        email: `${values.username}@${domain}`,
        domain,
        username: values.username,
      },
    })

    try {
      const response = await post(getOXBuyEmailEndpoints(), payload)
      if (response.data.success) {
        window.location = `/my-account/email-manager/${response.data.result.contextId}/dns-setup`
      }
    } catch (e) {
      setError(strings.defaultErrorMessage)
      setLoading(false)
    }
  }

  const validate = values => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Required'
    }
    if (values.username && !USERNAME_REGEX.test(values.username)) {
      errors.username = 'Invalid username'
    }
    return errors
  }

  const formik = useFormik({
    initialValues: {
      formikPassword: '',
      username: '',
    },
    validate,
    onSubmit: values => {
      onEmailSubmit(values)
    },
  })

  const handleSubmit = e => {
    e.preventDefault()
    formik.handleSubmit(formik.values)
  }

  useEffect(() => {
    if (score < 3 || !formik.values.username) {
      setDisabled(true)
    } else if (score >= 3 && formik.values.username) {
      setDisabled(false)
    }
  }, [score])

  useEffect(() => {
    setSectionTitle(strings.title(selectedPackage))
  }, [selectedPackage])

  return (
    <Stack width="100%">
      <Section
        bg="#453E63"
        containerProps={{ maxWidth: 'containers.md' }}
        id="cloud-purchase-config"
        px={2}
        py={[1, 4]}
      >
        <Title
          alignment={['center', 'center']}
          as="h1"
          lineHeight={['1.3']}
          mb={5}
          mt={4}
          title={{
            main: {
              text: strings.header,
              textColor: 'white',
            },
            bottom: {
              text: strings.subtitle,
              textColor: 'white',
            },
          }}
          variant={['teraBold', 'petaBold']}
          width="100%"
        />
      </Section>
      <Section
        bg="white"
        containerProps={{ maxWidth: 'containers.md ' }}
        py={[3, 5]}
      >
        <Box flexDirection="row" justifyContent="center">
          <Box display={['none', 'none', 'flex']} mr={4} width="300px">
            <Image
              alt="cloud-email-purchase"
              width="100%"
              {...{ fluid: data.file.childImageSharp.fluid }}
            />
          </Box>
          <Box flexDirection="column" m={2}>
            <Box flexDirection="column" mb={3}>
              <Box bg="#FFD17B" justifyContent="center" mb={2} width="40%">
                <Text
                  color="black"
                  px="1rem"
                  py="0.3rem"
                  textTransform="uppercase"
                  variant="milliBold"
                >
                  {strings.freeTrial}
                </Text>
              </Box>
              <Text fontFamily={fonts.title} variant="petaBold">
                {sectionTitle}
              </Text>
            </Box>
            <Box flexDirection="column" mb={3}>
              <ConfiguratorSelector
                allDynamicPrices={allDynamicPrices}
                onChange={handlePackageSelection}
                packages={
                  OXPackages.find(
                    resellerPkgs => resellerPkgs.reseller === RESELLER
                  ).data
                }
                selectedPackageId={selectedPackage}
              />
            </Box>
            <Box flexDirection="column">
              <Text fontFamily={fonts.title} variant="megaBold">
                {strings.addNewEmail}
              </Text>
              <Text variant="kilo">{strings.chooseCustomEmail}</Text>
            </Box>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Box flexDirection="column" mb={3} mt={3} width={['100%', '60%']}>
                <Text variant="milliBold"> {strings.accountName}</Text>
                <Box>
                  <Input
                    id="account-name-prefix"
                    type="text"
                    {...formik.getFieldProps('username')}
                    autoComplete="off"
                  />
                  <Input disabled id="domain" value={`@${domain}`} />
                </Box>
                {formik.touched.username && formik.errors.username ? (
                  <Text color="red" variant="milliBold">
                    {formik.errors.username}
                  </Text>
                ) : null}
              </Box>
              <Box flexDirection="column" width={['100%', '60%']}>
                <Text variant="milliBold"> {strings.password} </Text>
                <Box flexDirection="column">
                  <Input
                    id="password"
                    type="password"
                    width="100%"
                    {...formik.getFieldProps('formikPassword')}
                    autoComplete="new-password"
                  />
                  <Box mt={2}>
                    <PasswordStrengthBar
                      borderRadius="5px"
                      onChangeScore={s => setScore(s)}
                      password={formik.values.formikPassword}
                      showFeedback
                      showLabels
                    />
                  </Box>
                </Box>
                {formik.touched.password && formik.errors.password ? (
                  <Box>
                    <Text color="red" maxWidth="400px" variant="milliBold">
                      {formik.errors.password}
                    </Text>
                  </Box>
                ) : null}
              </Box>
              <Box mt={3} width={['100%', '60%']}>
                <Button
                  color={isInternic ? 'greenDark' : 'magenta'}
                  disabled={disableButton}
                  mr={2}
                  p={3}
                  type="submit"
                  width="100%"
                >
                  {loading ? (
                    <Stack flexDirection="row" style={{ overflow: 'hidden' }}>
                      <Text mr={2}>{STRINGS.loadingEmailCTA}</Text>
                      <Box alignSelf="start" flexDirection="column">
                        <Spinner color="white" size="1.5rem" stroke=".4rem" />
                      </Box>
                    </Stack>
                  ) : (
                    STRINGS.addUserButton
                  )}
                </Button>
              </Box>
            </form>
            {error && (
              <AlertInline
                body={error}
                my={2}
                notificationTitle="error"
                variant="error"
              />
            )}
            <Box mt={3}>
              <Text fontSize="1.35rem" textAlign={['left', 'center']}>
                {strings.terms}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
    </Stack>
  )
}

OXPurchaseConfig.propTypes = propTypes
export { OXPurchaseConfig }
