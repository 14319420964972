import React from 'react'
import { Stack, Text, ProgressIndicator } from '@rebeldotcom/components'
import PropTypes from 'prop-types'

const propTypes = {
  user: PropTypes.shape({
    limitInKB: PropTypes.string,
    usageInKB: PropTypes.string,
  }).isRequired,
}

/**
 * Returns a GiB value based on KiB input
 * @param {number} kibibytes
 * @returns number
 */
const kibPerGiB = 1048576
const kibPerMiB = 1024
const calculateGiB = kibibytes => {
  if (!kibibytes || kibibytes <= 0) return 0

  return kibibytes / kibPerGiB
}

const calculateMiB = kibibytes => {
  if (!kibibytes || kibibytes <= 0) return 0

  return kibibytes / kibPerMiB
}

/**
 * Takes a number and formats it to round such that
 * '2.1GB of 3GB' used, and '0' instead of '0.0'
 * @param {number} number The decimal number to format
 * @param {number} fractionDigits Number of decimals
 * @returns string
 */
const formatFixed = (number, fractionDigits = 0) => {
  return number.toFixed(fractionDigits).replace(/\.+0+$/, '')
}

const DiskStorage = ({ user }) => {
  const limitInGiB = user?.limitInKB
    ? formatFixed(calculateGiB(user?.limitInKB), 0)
    : 100
  const usageInGiB =
    user?.usageInKB > 0 ? formatFixed(calculateGiB(user?.usageInKB), 1) : 0
  const usageInMiB =
    user?.usageInKB > 0 ? formatFixed(calculateMiB(user?.usageInKB), 1) : 0

  const diskStorageDisplay =
    user?.usageInKB < kibPerGiB
      ? `${usageInMiB} MB ${user?.limitInKB ? `of ${limitInGiB} GB used` : ''}`
      : `${usageInGiB} GB ${user?.limitInKB ? `of ${limitInGiB} GB used` : ''}`

  return (
    <Stack justifyContent="center">
      <Text lineHeight="20px" pt={1}>
        {diskStorageDisplay}
      </Text>
      <ProgressIndicator height="5px" max={limitInGiB} value={usageInGiB} />
    </Stack>
  )
}

DiskStorage.propTypes = propTypes
export default DiskStorage
