export default [
  {
    reseller: 'rebel.com',
    data: [
      {
        id: 'cloud-basic',
        packageId: 1,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_BASIC',
        itemCode: 'CLOUD_BASIC',
        title: 'Cloud Basic',
        itemDescription: 'Cloud Basic Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_BASIC_USER',
          suffix: '/user/mo',
        },
        features: ['<strong>3 GB</strong> email storage'],
      },
      {
        id: 'cloud-productivity',
        packageId: 2,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_PRO',
        itemCode: 'CLOUD_PRO',
        title: 'Cloud Productivity',
        itemDescription: 'Cloud Productivity Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_PRO_USER',
          suffix: '/user/mo',
        },
        features: [
          '<strong>10 GB</strong> email storage',
          '<strong>Cloud</strong> storage',
          '<strong>Productivity</strong> app suite',
        ],
      },
      {
        id: 'cloud-security',
        packageId: 7,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_SEC',
        itemCode: 'CLOUD_SEC',
        title: 'Cloud Security+',
        itemDescription: 'Cloud Security+ Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_SEC_USER',
          suffix: '/user/mo',
        },
        features: [
          '<strong>30 GB</strong> email storage',
          '<strong>Cloud</strong> storage',
          '<strong>Productivity</strong> app suite',
          'Real-time <strong>anti-phishing</strong>',
          'Email and file <strong>encryption</strong>',
        ],
      },
    ],
  },
  {
    reseller: 'rebel.ca',
    data: [
      {
        id: 'cloud-basic',
        packageId: 3,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_BASIC',
        itemCode: 'CLOUD_BASIC',
        title: 'Cloud Basic',
        itemDescription: 'Cloud Basic Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_BASIC_USER',
          suffix: '/user/mo',
        },
        features: ['<strong>3 GB</strong> email storage'],
      },
      {
        id: 'cloud-productivity',
        packageId: 4,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_PRO',
        itemCode: 'CLOUD_PRO',
        title: 'Cloud Productivity',
        itemDescription: 'Cloud Productivity Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_PRO_USER',
          suffix: '/user/mo',
        },
        features: [
          '<strong>10 GB</strong> email storage',
          '<strong>Cloud</strong> storage',
          '<strong>Productivity</strong> app suite',
        ],
      },
      {
        id: 'cloud-security',
        packageId: 8,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_SEC',
        itemCode: 'CLOUD_SEC',
        title: 'Cloud Security+',
        itemDescription: 'Cloud Security+ Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_SEC_USER',
          suffix: '/user/mo',
        },
        features: [
          '<strong>30 GB</strong> email storage',
          '<strong>Cloud</strong> storage',
          '<strong>Productivity</strong> app suite',
          'Real-time <strong>anti-phishing</strong>',
          'Email and file <strong>encryption</strong>',
        ],
      },
    ],
  },
  {
    reseller: 'internic.ca',
    data: [
      {
        id: 'cloud-basic',
        packageId: 5,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_BASIC',
        itemCode: 'CLOUD_BASIC',
        title: 'Cloud Basic',
        itemDescription: 'Cloud Basic Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_BASIC_USER',
          suffix: '/user/mo',
        },
        features: ['<strong>3 GB</strong> email storage'],
      },
      {
        id: 'cloud-productivity',
        packageId: 6,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_PRO',
        itemCode: 'CLOUD_PRO',
        title: 'Cloud Productivity',
        itemDescription: 'Cloud Productivity Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_PRO_USER',
          suffix: '/user/mo',
        },
        features: [
          '<strong>10 GB</strong> email storage',
          '<strong>Cloud</strong> storage',
          '<strong>Productivity</strong> app suite',
        ],
      },
      {
        id: 'cloud-security',
        packageId: 9,
        addToCartPath:
          'api/openexchange/accounts/{{domain}}/change-package/CLOUD_SEC',
        itemCode: 'CLOUD_SEC',
        title: 'Cloud Security+',
        itemDescription: 'Cloud Security+ Email',
        product: 'Email',
        productCategory: 'Email',
        productType: 78,
        returnPath: '/order',
        source: 'Configurator',
        price: {
          itemCode: 'CLOUD_SEC_USER',
          suffix: '/user/mo',
        },
        features: [
          '<strong>30 GB</strong> email storage',
          '<strong>Cloud</strong> storage',
          '<strong>Productivity</strong> app suite',
          'Real-time <strong>anti-phishing</strong>',
          'Email and file <strong>encryption</strong>',
        ],
      },
    ],
  },
]
