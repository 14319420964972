export const settingLinksStrings = {
  addressTitle: 'Email Address',
  passwordTitle: 'Password',
  passwordDisplay: '********',
  forwardingTitle: 'Forwarding',
  forwardingNone: 'No Forwards Registered',
  forwardDisplay: count => `${count} Forwarded Addresses`,
  aliasesTitle: 'Aliases',
  aliasesNone: 'None',
  aliasesDisplay: count => `${count} Aliases Registered`,
  spamTitle: 'Allowlist / Blocklist',
  spamHigh: 'Enabled - High',
  spamLow: 'Enabled - Low',
  spamNone: 'Disabled',
  autoResponderTitle: 'Autoresponder',
  autoResponderEnabled: 'Enabled',
  autoResponderDisabled: 'Disabled',
  emailStorageTitle: 'Email Storage',
  deleteEmailTitle: 'Delete Email Address',
  deleteEmailDisplay: '',
}

export const ChangeEmailAddressStrings = {
  title: 'Change Email Address',
  disclaimer:
    'When you update your email address, all mail will be kept, as well as all your settings. If this email address is set up in any email clients, you will need to update the settings after you change the address.', // If you will likely still receive emails at the original address, you can set up an alias to direct the mail into this mailbox.
  success: 'Email address successfully changed.',
  error: 'Error changing your email address.',
  save: 'Save',
  saving: 'Saving...',
}
