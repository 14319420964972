import React from 'react'
import {
  Stack,
  Heading,
  Section,
  Text,
  Skeleton,
} from '@rebeldotcom/components'
import {
  isInternic,
  useOpenExchangeService,
  useUserService,
} from '@rebeldotcom/ui'
import Breadcrumbs from '../../../breadcrumbs'
import { orderRouteAbsolute } from './breadcrumb-routes'
import SettingsOption from './settings-option'
import DiskStorage from './disk-storage'
import { settingLinksStrings } from './settings.strings'
import STRINGS from './ox.strings'

export const BASE_URL = '/my-account/email-manager'

// Getting passed email id in the URL
const EmailSettings = () => {
  const {
    currentContext,
    currentUserId,
    currentEmailUser,
    navigate,
    providerLoading,
  } = useOpenExchangeService()

  // Replace console logs with redirects to pages when they're built
  const settingsActions = [
    {
      title: settingLinksStrings.passwordTitle,
      display: settingLinksStrings.passwordDisplay,
      onClick: () => {
        navigate(`${BASE_URL}/${currentUserId}/change-password`)
      },
    },
    {
      title: settingLinksStrings.forwardingTitle,
      display:
        currentEmailUser?.forwards && currentEmailUser?.forwards.length > 0
          ? settingLinksStrings.forwardDisplay(
              currentEmailUser?.forwards.length
            )
          : settingLinksStrings.forwardingNone,
      onClick: () => {
        navigate(`${BASE_URL}/${currentUserId}/forwarding`)
      },
    },
    {
      title: settingLinksStrings.aliasesTitle,
      display:
        currentEmailUser?.aliases && currentEmailUser?.aliases.length > 0
          ? settingLinksStrings.aliasesDisplay(currentEmailUser?.aliases.length)
          : settingLinksStrings.aliasesNone,
      onClick: () => {
        navigate(`${BASE_URL}/${currentUserId}/aliases`)
      },
    },
    {
      title: settingLinksStrings.spamTitle,
      display:
        // eslint-disable-next-line no-nested-ternary
        currentEmailUser?.spamProtection === 2
          ? settingLinksStrings.spamHigh
          : currentEmailUser?.spamProtection === 1
          ? settingLinksStrings.spamLow
          : settingLinksStrings.spamNone,
      onClick: () => {
        console.log('SPAM SETTINGS')
      },
      hide: true,
    },
    {
      title: settingLinksStrings.autoResponderTitle,
      display:
        // We'll need to fix this since we don't get autoResponder back in the payload, so it'll always display Disabled
        currentEmailUser?.autoResponder === true
          ? settingLinksStrings.autoResponderEnabled
          : settingLinksStrings.autoResponderDisabled,
      onClick: () => {
        navigate(`${BASE_URL}/${currentUserId}/autoresponder`)
      },
    },
    {
      title: settingLinksStrings.emailStorageTitle,
      display: (
        <DiskStorage
          user={{
            usageInKB: currentEmailUser?.usageInKB,
            limitInKB: currentEmailUser?.limitInKB,
          }}
        />
      ),
      onClick: () => {
        navigate(`${BASE_URL}/${currentContext?.contextId}/change-package`, {
          state: {
            contextId: currentContext?.contextId,
          },
        })
      },
      iconText: (
        <Text color="#00819E" variant="megaBold">
          {STRINGS.upgrade}
        </Text>
      ),
      hideIcon: true,
    },
    {
      title: <Text color="red">{settingLinksStrings.deleteEmailTitle}</Text>,
      onClick: () => {
        navigate(`${BASE_URL}/${currentUserId}/delete`)
      },
      icon: { name: 'close', width: '3rem', height: '3rem', color: 'red' },
    },
  ]

  const breadcrumbRoutes = [
    { display: 'Email Manager', onClick: () => navigate(orderRouteAbsolute) },
    {
      display: `${
        currentEmailUser ? currentEmailUser.userName : 'Loading User...'
      }`,
      onClick: () => navigate(`${orderRouteAbsolute}/${currentUserId}`),
    },
  ]

  return (
    <Section
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      mb={3}
      pb={[4, 5]}
      pt={[2, 3]}
      px={[3, 4]}
    >
      <Stack width="80%">
        <Breadcrumbs
          crumbs={breadcrumbRoutes}
          justifyContent={['center', 'flex-start', 'flex-start']}
          mb={2}
          selectedCrumb={1}
        />
        <Heading mb={3} variant="petaBold">
          {STRINGS.settings}
        </Heading>
        <Stack width={['100%', '70%', '70%']}>
          {providerLoading ? (
            <Skeleton height={['40rem', '50rem']} />
          ) : (
            settingsActions.map((option, index) => {
              if (option.hide) return null
              return (
                <SettingsOption
                  key={`option-${index}`}
                  first={index === 0}
                  option={option}
                />
              )
            })
          )}
        </Stack>
      </Stack>
    </Section>
  )
}

export default EmailSettings
