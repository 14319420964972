import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Heading,
  Text,
  Message,
  Button,
  Box,
  InputStepper,
} from '@rebeldotcom/components'
import { addToCart, useSessionService, Price } from '@rebeldotcom/ui'

const propTypes = {
  selectedPackage: PropTypes.shape({
    addToCartPath: PropTypes.string,
    amount: PropTypes.number,
    costPerUser: PropTypes.shape({
      suffix: PropTypes.string,
    }),
    id: PropTypes.string,
    itemCode: PropTypes.string,
    itemDescription: PropTypes.string,
    packageId: PropTypes.number,
    product: PropTypes.string,
    productCategory: PropTypes.string,
    returnPath: PropTypes.string,
    source: PropTypes.string,
  }),
}

const defaultProps = {
  selectedPackage: {},
}

const GoogleWorkspace = ({ selectedPackage }) => {
  const [userCount, setUserCount] = useState(1)
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()

  const { costPerUser } = selectedPackage

  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmit = async () => {
    try {
      const {
        addToCartPath,
        amount,
        itemCode,
        itemDescription,
        product,
        productCategory,
        returnPath,
        source,
        packageId,
      } = selectedPackage

      const result = await addToCart({
        addToCartPath,
        addToCartParams: {
          packageId,
          users: userCount,
        },
        amount,
        itemCode,
        itemDescription,
        product,
        productCategory,
        returnPath,
        source,
        method: 'post',
      })
      if (!result.valid) {
        setErrorMessage(result.error.response.data.message)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error', err)
    }
  }

  const total = `$${(
    userCount * Price.getFloatPrice(costPerUser, currentCode)
  ).toFixed(2)}/month`
  const perUserDisplayPrice = `@ ${Price.getDisplayPrice(
    costPerUser,
    currentCode
  )} ${costPerUser ? costPerUser.suffix : ''}`

  return (
    <Stack mt={4} mx={[3]} width={['100%', '50%']}>
      <Heading variant="teraBold">How many users do you need?</Heading>
      <Box alignItems="center" justifyContent="space-between" mt={3}>
        <InputStepper
          ariaLabel="Add extra users to your Google Workspace"
          count={userCount}
          id="google-workspace-users"
          maxValue={300}
          minValue={1}
          onChange={setUserCount}
          width="13rem"
        />

        <Box>
          <Text>{perUserDisplayPrice}</Text>
        </Box>
      </Box>

      <Box alignItems="center" justifyContent="space-between" mt={5}>
        <Heading variant="teraBold">Total</Heading>
        <Text alignSelf="flex-end" variant="gigaBold">
          {currentCode.toUpperCase()} {total}
        </Text>
      </Box>

      {errorMessage && (
        <Message mt={3} variant="error">
          {errorMessage}
        </Message>
      )}

      <Button
        alignSelf="flex-start"
        color="blueDark"
        id="google-workspace-checkout"
        mt={3}
        onClick={onSubmit}
      >
        Continue to Checkout
      </Button>
    </Stack>
  )
}

GoogleWorkspace.propTypes = propTypes
GoogleWorkspace.defaultProps = defaultProps

export { GoogleWorkspace }
