import { Input, swiftTheme, redesignTheme } from '@rebeldotcom/components'
import styled from 'styled-components'

const { colors } = swiftTheme
const { fonts, textVariants } = redesignTheme

const emailSymbol = () => ({
  alignItems: 'space-between',
  display: 'flex',

  ':before': {
    content: '"@"',
    display: 'block',
    marginRight: 8,
    alignSelf: 'center',
  },
})

const createStyles = error => ({
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    minWidth: '3rem',
    fontFamily: fonts.body,
    backgroundColor: '#222244',
    color: colors.neutral[200],
    borderRadius: 0,
    margin: 0,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    border: '#2680EB 1px solid',
    '& [id*="-option-"]:hover': {
      backgroundColor: colors.primary[700],
    },
  }),
  menuList: provided => ({
    ...provided,
    '::-webkit-scrollbar': {
      background: colors.primary[700],
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      background: colors.primary[800],
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.primary[500],
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.primary[500],
    },
  }),
  option: provided => ({
    ...provided,
    ...textVariants.pound,
    textAlign: 'end',
    paddingRight: '3rem',
    fontFamily: fonts.body,
    color: colors.neutral[200],
    backgroundColor: '#222244',
  }),
  control: (base, state) => {
    let borderColor = '#7070ce'
    // eslint-disable-next-line prefer-destructuring
    if (error) borderColor = colors.destructive[500]
    if (state.menuIsOpen) borderColor = '#2680EB'
    return {
      ...base,
      backgroundColor: '#222244',
      color: '#FFFFFF',
      boxShadow: '#7070ce',
      borderColor,
      borderRadius: 0,
      padding: state.selectProps.padding,
      width: state.selectProps.width,
      height: state.selectProps.height,
      minHeight: state.selectProps.height + 5,
    }
  },
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: state.selectProps.height,
    '& svg': {
      color: colors.neutral[200],
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#222244',
  }),
  valueContainer: provided => ({
    ...provided,
    ...emailSymbol(),
    paddingRight: 0,
    '& [id*="-placeholder"]': {
      position: 'absolute',
      textAlign: 'end',
      color: colors.neutral[500],
      paddingTop: '.2rem',
      fontSize: '1.5rem',
      right: '0',
    },
  }),
  singleValue: provided => ({
    ...provided,
    ...textVariants.pound,
    fontFamily: fonts.body,
    alignSelf: 'center',
    color: colors.neutral[200],
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#FFFFFF',
  }),
  input: styles => ({
    ...styles,
    ...textVariants.pound,
    margin: 0,
    textAlign: 'end',
    fontFamily: fonts.body,
    color: colors.neutral[200],
  }),
})

const ModInput = styled(Input)`
  & input {
    font-size: 1.5rem;
    background-color: #222244;
    border-color: ${props => props.borderColor || '#7070ce'};
    color: #ffffff;
    height: 37.99px;

    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:focus,
    &:active {
      border-color: #2680eb;
      background-color: #222244;
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    ::placeholder {
      color: ${colors.neutral[500]};
    }
  }

  & #showpassword {
    color: #ffffff;
    z-index: auto;
  }
`

export { createStyles, ModInput }
