import { RESELLER, isInternic } from '@rebeldotcom/ui'

const resellerApiSite = isInternic ? 'internic.ca' : 'rebel.com'

const SKYWALKER_ENDPOINTS = {
  openExchange: 'open-exchange/',
  domains: 'domains/',
}

const getAddToCartEndpoint = () => {
  const domainAddToCart = `api/domaintransfer/add-to-cart`

  const domainAddToCartProd = {
    domainAddToCartEndpoint: `https://www.${RESELLER}/${domainAddToCart}`,
  }

  const domainAddToCartDev = {
    domainAddToCartEndpoint: `https://dev.${RESELLER}/${domainAddToCart}`,
  }

  const domainAddToCartLocal = {
    domainAddToCartEndpoint: `https://local.${RESELLER}/${domainAddToCart}`,
  }

  const { domainAddToCartEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && domainAddToCartLocal) ||
    (__DEV__ && domainAddToCartDev) ||
    (__PROD__ && domainAddToCartProd)

  return domainAddToCartEndpoint
}

const getDomainTransferEndpoint = domainName => {
  const domainTransferPath = `api/domaintransfer/${domainName}/check`

  const domainTransferCheckProd = {
    domainTransferCheckEndpoint: `https://www.${RESELLER}/${domainTransferPath}`,
  }

  const domainTransferCheckDev = {
    domainTransferCheckEndpoint: `https://dev.${RESELLER}/${domainTransferPath}`,
  }

  const domainTransferCheckLocal = {
    domainTransferCheckEndpoint: `https://local.${RESELLER}/${domainTransferPath}`,
  }

  const { domainTransferCheckEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && domainTransferCheckLocal) ||
    (__DEV__ && domainTransferCheckDev) ||
    (__PROD__ && domainTransferCheckProd)

  return domainTransferCheckEndpoint
}

const getSearchLink = domain => {
  const domainSearchPath = `search/?exact=true&q=${domain}`

  const domainSearchProd = {
    domainSearchLink: `https://www.${RESELLER}/${domainSearchPath}`,
  }

  const domainSearchDev = {
    domainSearchLink: `https://dev.${RESELLER}/${domainSearchPath}`,
  }

  const domainSearchLocal = {
    domainSearchLink: `https://local.${RESELLER}/${domainSearchPath}`,
  }

  const { domainSearchLink } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && domainSearchLocal) ||
    (__DEV__ && domainSearchDev) ||
    (__PROD__ && domainSearchProd)

  return domainSearchLink
}

const getUserAddressEndpoint = () => {
  const userAddress = `api/user/address`

  const userAddressEndpointProd = {
    userAddressEndpoint: `https://www.${RESELLER}/${userAddress}`,
  }

  const userAddressEndpointDev = {
    userAddressEndpoint: `https://dev.${RESELLER}/${userAddress}`,
  }

  const userAddressEndpointLocal = {
    userAddressEndpoint: `https://local.${RESELLER}/${userAddress}`,
  }

  const { userAddressEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && userAddressEndpointLocal) ||
    (__DEV__ && userAddressEndpointDev) ||
    (__PROD__ && userAddressEndpointProd)

  return userAddressEndpoint
}

const getUserDetailsEndpoint = () => {
  const userDetails = `api/user/details`

  const userDetailsEndpointProd = {
    userDetailsEndpoint: `https://www.${RESELLER}/${userDetails}`,
  }

  const userDetailsEndpointDev = {
    userDetailsEndpoint: `https://dev.${RESELLER}/${userDetails}`,
  }

  const userDetailsEndpointLocal = {
    userDetailsEndpoint: `https://local.${RESELLER}/${userDetails}`,
  }

  const { userDetailsEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && userDetailsEndpointLocal) ||
    (__DEV__ && userDetailsEndpointDev) ||
    (__PROD__ && userDetailsEndpointProd)

  return userDetailsEndpoint
}

const getUserPasswordEndpoint = () => {
  const userPassword = `api/user/resetpw`

  const userPasswordEndpointProd = {
    userPasswordEndpoint: `https://www.${RESELLER}/${userPassword}`,
  }

  const userPasswordEndpointDev = {
    userPasswordEndpoint: `https://dev.${RESELLER}/${userPassword}`,
  }

  const userPasswordEndpointLocal = {
    userPasswordEndpoint: `https://local.${RESELLER}/${userPassword}`,
  }

  const { userPasswordEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && userPasswordEndpointLocal) ||
    (__DEV__ && userPasswordEndpointDev) ||
    (__PROD__ && userPasswordEndpointProd)

  return userPasswordEndpoint
}

const getUserSunshineJWTEndpoint = () => {
  const endpoint = `api/user/sunshine/jwt`

  const userSunshineEndpointProd = {
    userSunshineEndpoint: `https://www.${RESELLER}/${endpoint}`,
  }

  const userSunshineEndpointDev = {
    userSunshineEndpoint: `https://dev.${RESELLER}/${endpoint}`,
  }

  const userSunshineEndpointLocal = {
    userSunshineEndpoint: `https://local.${RESELLER}/${endpoint}`,
  }

  const { userSunshineEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && userSunshineEndpointLocal) ||
    (__DEV__ && userSunshineEndpointDev) ||
    (__PROD__ && userSunshineEndpointProd)

  return userSunshineEndpoint
}

const getLoginHistoryEndpoint = (offset = 0, limit = null) => {
  const loginHistory = `api/user/loginhistory?offset=${offset}&limit=${limit}`

  const loginHistoryProd = {
    loginHistoryEndpoint: `https://www.${RESELLER}/${loginHistory}`,
  }

  const loginHistoryDev = {
    loginHistoryEndpoint: `https://dev.${RESELLER}/${loginHistory}`,
  }

  const loginHistoryLocal = {
    loginHistoryEndpoint: `https://local.${RESELLER}/${loginHistory}`,
  }

  const { loginHistoryEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && loginHistoryLocal) ||
    (__DEV__ && loginHistoryDev) ||
    (__PROD__ && loginHistoryProd)

  return loginHistoryEndpoint
}

const getBillingHistoryEndpoint = (
  startDate = null,
  endDate = null,
  cc = null,
  offset = 0,
  limit = null
) => {
  const billingHistory = `api/user/billinghistory?${
    startDate && endDate ? `startDate=${startDate}&endDate=${endDate}` : ''
  }${cc ? `&cc=${cc}` : ''}&offset=${offset}&limit=${limit}&page=1`

  const billingHistoryProd = {
    billingHistoryEndpoint: `https://www.${RESELLER}/${billingHistory}`,
  }

  const billingHistoryDev = {
    billingHistoryEndpoint: `https://dev.${RESELLER}/${billingHistory}`,
  }

  const billingHistoryLocal = {
    billingHistoryEndpoint: `https://local.${RESELLER}/${billingHistory}`,
  }

  const { billingHistoryEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && billingHistoryLocal) ||
    (__DEV__ && billingHistoryDev) ||
    (__PROD__ && billingHistoryProd)

  return billingHistoryEndpoint
}

const getBillingHistoryEndpointNew = (
  startDate = null,
  endDate = null,
  page = 1
) => {
  let billingHistory = `api/user/billinghistory?limit=15&page=${page}`

  if (startDate) {
    billingHistory += `&startDate=${startDate}`
  }

  if (endDate) {
    billingHistory += `&endDate=${endDate}`
  }

  const billingHistoryProd = {
    billingHistoryEndpoint: `https://www.${RESELLER}/${billingHistory}`,
  }

  const billingHistoryDev = {
    billingHistoryEndpoint: `https://dev.${RESELLER}/${billingHistory}`,
  }

  const billingHistoryLocal = {
    billingHistoryEndpoint: `https://local.${RESELLER}/${billingHistory}`,
  }

  const { billingHistoryEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && billingHistoryLocal) ||
    (__DEV__ && billingHistoryDev) ||
    (__PROD__ && billingHistoryProd)

  return billingHistoryEndpoint
}

const getDownloadLinkEndpoint = invoiceId => {
  const downloadUrl = `api/user/invoices/${invoiceId}/download`

  const downloadUrlProd = {
    billingHistoryEndpoint: `https://www.${RESELLER}/${downloadUrl}`,
  }

  const downloadUrlDev = {
    billingHistoryEndpoint: `https://dev.${RESELLER}/${downloadUrl}`,
  }

  const downloadUrlLocal = {
    billingHistoryEndpoint: `https://local.${RESELLER}/${downloadUrl}`,
  }

  const { billingHistoryEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && downloadUrlLocal) ||
    (__DEV__ && downloadUrlDev) ||
    (__PROD__ && downloadUrlProd)

  return billingHistoryEndpoint
}

const getPrintDocumentASPX = (docId = null) => {
  const printDocumentASPX = `MyAccount/PrintDocument.aspx?${
    docId ? `docId=${docId}` : ''
  }`

  const printDocumentASPXProd = {
    printDocumentASPXEndpoint: `https://www.${RESELLER}/${printDocumentASPX}`,
  }

  const printDocumentASPXDev = {
    printDocumentASPXEndpoint: `https://dev.${RESELLER}/${printDocumentASPX}`,
  }

  const printDocumentASPXLocal = {
    printDocumentASPXEndpoint: `https://local.${RESELLER}/${printDocumentASPX}`,
  }

  const { printDocumentASPXEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && printDocumentASPXLocal) ||
    (__DEV__ && printDocumentASPXDev) ||
    (__PROD__ && printDocumentASPXProd)

  return printDocumentASPXEndpoint
}

const getPrintOrderASPX = (orderId = null) => {
  const printDocumentASPX = `MyAccount/PrintOrder.aspx?${
    orderId ? `orderid=${orderId}` : ''
  }`

  const printDocumentASPXProd = {
    printDocumentASPXEndpoint: `https://www.${RESELLER}/${printDocumentASPX}`,
  }

  const printDocumentASPXDev = {
    printDocumentASPXEndpoint: `https://dev.${RESELLER}/${printDocumentASPX}`,
  }

  const printDocumentASPXLocal = {
    printDocumentASPXEndpoint: `https://local.${RESELLER}/${printDocumentASPX}`,
  }

  const { printDocumentASPXEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && printDocumentASPXLocal) ||
    (__DEV__ && printDocumentASPXDev) ||
    (__PROD__ && printDocumentASPXProd)

  return printDocumentASPXEndpoint
}

const getBillingHistoryCSVEndpoint = (exportType = 'partial') => {
  const BillingHistoryCSVEndpoint = `MyAccount/BillingHistoryCSVExport.aspx?exporttype=${exportType}`

  const BillingHistoryCSVEndpointProd = {
    BillingHistoryCSVEndpointEndpoint: `https://www.${RESELLER}/${BillingHistoryCSVEndpoint}`,
  }

  const BillingHistoryCSVEndpointDev = {
    BillingHistoryCSVEndpointEndpoint: `https://dev.${RESELLER}/${BillingHistoryCSVEndpoint}`,
  }

  const BillingHistoryCSVEndpointLocal = {
    BillingHistoryCSVEndpointEndpoint: `https://local.${RESELLER}/${BillingHistoryCSVEndpoint}`,
  }

  const { BillingHistoryCSVEndpointEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && BillingHistoryCSVEndpointLocal) ||
    (__DEV__ && BillingHistoryCSVEndpointDev) ||
    (__PROD__ && BillingHistoryCSVEndpointProd)

  return BillingHistoryCSVEndpointEndpoint
}

const getAddToCartWithDomainEndpoint = () => {
  const domainAddToCart = `/api/shoppingcart/addToCartWithDomain`

  const domainAddToCartProd = {
    domainAddToCartEndpoint: `https://www.${RESELLER}/${domainAddToCart}`,
  }

  const domainAddToCartDev = {
    domainAddToCartEndpoint: `https://dev.${RESELLER}/${domainAddToCart}`,
  }

  const domainAddToCartLocal = {
    domainAddToCartEndpoint: `https://local.${RESELLER}/${domainAddToCart}`,
  }

  const { domainAddToCartEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && domainAddToCartLocal) ||
    (__DEV__ && domainAddToCartDev) ||
    (__PROD__ && domainAddToCartProd)

  return domainAddToCartEndpoint
}

const getAccountDomains = () => {
  const localDomains = `https://local.${RESELLER}/api/user/domains/`
  const devDomains = `https://dev.${RESELLER}/api/user/domains/`
  const prodDomains = `https://www.${RESELLER}/api/user/domains/`
  const accountDomains =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && localDomains) ||
    (__DEV__ && devDomains) ||
    (__PROD__ && prodDomains)

  return accountDomains
}

const getResellerBaseURL = () => {
  const Prod = {
    endpoint: `https://www.${RESELLER}/`,
  }

  const Dev = {
    endpoint: `https://dev.${RESELLER}/`,
  }

  const Local = {
    endpoint: `https://local.${RESELLER}/`,
  }

  const { endpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && Local) || (__DEV__ && Dev) || (__PROD__ && Prod)

  return endpoint
}

const getOXBuyEmailEndpoints = () => {
  const oxBuyEmailPath = `api/openexchange/add-to-cart`

  const oxBuyEmailEndpointProd = {
    oxBuyEmailEndpoint: `https://www.${RESELLER}/${oxBuyEmailPath}`,
  }

  const oxBuyEmailEndpointDev = {
    oxBuyEmailEndpoint: `https://dev.${RESELLER}/${oxBuyEmailPath}`,
  }

  const oxBuyEmailEndpointLocal = {
    oxBuyEmailEndpoint: `https://local.${RESELLER}/${oxBuyEmailPath}`,
  }

  const { oxBuyEmailEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && oxBuyEmailEndpointLocal) ||
    (__DEV__ && oxBuyEmailEndpointDev) ||
    (__PROD__ && oxBuyEmailEndpointProd)

  return oxBuyEmailEndpoint
}

const getOXDNSTypeEndpoints = contextId => {
  const oxDNSTypePath = `api/openexchange/accounts/${contextId}/get-dns-type`

  const oxBuyEmailEndpointProd = {
    oxDNSTypeEndpoint: `https://www.${RESELLER}/${oxDNSTypePath}`,
  }

  const oxBuyEmailEndpointDev = {
    oxDNSTypeEndpoint: `https://dev.${RESELLER}/${oxDNSTypePath}`,
  }

  const oxBuyEmailEndpointLocal = {
    oxDNSTypeEndpoint: `https://local.${RESELLER}/${oxDNSTypePath}`,
  }

  const { oxDNSTypeEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && oxBuyEmailEndpointLocal) ||
    (__DEV__ && oxBuyEmailEndpointDev) ||
    (__PROD__ && oxBuyEmailEndpointProd)

  return oxDNSTypeEndpoint
}

const getOXSetupEndpoints = contextId => {
  const oxDNSTypePath = `api/openexchange/accounts/${contextId}/updateDNS`

  const endpointProd = {
    oxDNSTypeEndpoint: `https://www.${RESELLER}/${oxDNSTypePath}`,
  }

  const endpointDev = {
    oxDNSTypeEndpoint: `https://dev.${RESELLER}/${oxDNSTypePath}`,
  }

  const endpointLocal = {
    oxDNSTypeEndpoint: `https://local.${RESELLER}/${oxDNSTypePath}`,
  }

  const { oxDNSTypeEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && endpointLocal) ||
    (__DEV__ && endpointDev) ||
    (__PROD__ && endpointProd)

  return oxDNSTypeEndpoint
}

const buildSkywalkerEndpoint = endpoint => {
  const endpointProd = {
    path: `https://api.${resellerApiSite}/${endpoint}`,
  }
  const endpointDev = {
    path: `https://dev.api.${resellerApiSite}/${endpoint}`,
  }
  const endpointLocal = {
    path: `http://localhost:3001/${endpoint}`,
  }

  const { path } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && endpointLocal) ||
    (__DEV__ && endpointDev) ||
    (__PROD__ && endpointProd)

  return path
}

const getHostingPackageBySubscriptionId = () => {
  const hostingPackageBySubIdPath = `api/subscription/hosting`

  const hostingPackageBySubIdProd = {
    hostingPackageBySubId: `https://www.${RESELLER}/${hostingPackageBySubIdPath}`,
  }

  const hostingPackageBySubIdDev = {
    hostingPackageBySubId: `https://dev.${RESELLER}/${hostingPackageBySubIdPath}`,
  }

  const hostingPackageBySubIdLocal = {
    hostingPackageBySubId: `https://local.${RESELLER}/${hostingPackageBySubIdPath}`,
  }

  const { hostingPackageBySubId } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && hostingPackageBySubIdLocal) ||
    (__DEV__ && hostingPackageBySubIdDev) ||
    (__PROD__ && hostingPackageBySubIdProd)

  return hostingPackageBySubId
}

const getMarketgooSubscriptions = () => {
  const marketgooURI = `api/marketgoo/subscriptions`

  const endpointProd = {
    fetchMarketgooEndpoint: `https://www.${RESELLER}/${marketgooURI}`,
  }

  const endpointDev = {
    fetchMarketgooEndpoint: `https://dev.${RESELLER}/${marketgooURI}`,
  }

  const endpointLocal = {
    fetchMarketgooEndpoint: `https://local.${RESELLER}/${marketgooURI}`,
  }

  const { fetchMarketgooEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && endpointLocal) ||
    (__DEV__ && endpointDev) ||
    (__PROD__ && endpointProd)

  return fetchMarketgooEndpoint
}

const cancelMarketgooAccount = pkMarketgooAccountId => {
  const marketgooURI = `api/marketgoo/${pkMarketgooAccountId}/cancel`

  const endpointProd = {
    cancelMarketgooEndpoint: `https://www.${RESELLER}/${marketgooURI}`,
  }

  const endpointDev = {
    cancelMarketgooEndpoint: `https://dev.${RESELLER}/${marketgooURI}`,
  }

  const endpointLocal = {
    cancelMarketgooEndpoint: `https://local.${RESELLER}/${marketgooURI}`,
  }

  const { cancelMarketgooEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && endpointLocal) ||
    (__DEV__ && endpointDev) ||
    (__PROD__ && endpointProd)

  return cancelMarketgooEndpoint
}

const reactivateMarketgooAccount = pkMarketgooAccountId => {
  const marketgooURI = `api/marketgoo/${pkMarketgooAccountId}/reactivate`

  const endpointProd = {
    reactivateMarketgooEndpoint: `https://www.${RESELLER}/${marketgooURI}`,
  }

  const endpointDev = {
    reactivateMarketgooEndpoint: `https://dev.${RESELLER}/${marketgooURI}`,
  }

  const endpointLocal = {
    reactivateMarketgooEndpoint: `https://local.${RESELLER}/${marketgooURI}`,
  }

  const { reactivateMarketgooEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && endpointLocal) ||
    (__DEV__ && endpointDev) ||
    (__PROD__ && endpointProd)

  return reactivateMarketgooEndpoint
}

export {
  SKYWALKER_ENDPOINTS,
  buildSkywalkerEndpoint,
  getAddToCartEndpoint,
  getBillingHistoryEndpoint,
  getBillingHistoryEndpointNew,
  getBillingHistoryCSVEndpoint,
  getDomainTransferEndpoint,
  getDownloadLinkEndpoint,
  getSearchLink,
  getUserAddressEndpoint,
  getUserDetailsEndpoint,
  getUserPasswordEndpoint,
  getLoginHistoryEndpoint,
  getPrintDocumentASPX,
  getPrintOrderASPX,
  getAddToCartWithDomainEndpoint,
  getAccountDomains,
  getResellerBaseURL,
  getOXBuyEmailEndpoints,
  getUserSunshineJWTEndpoint,
  getHostingPackageBySubscriptionId,
  getOXDNSTypeEndpoints,
  getOXSetupEndpoints,
  getMarketgooSubscriptions,
  cancelMarketgooAccount,
  reactivateMarketgooAccount,
}
