import { Price } from '@rebeldotcom/ui'
import { graphql, useStaticQuery } from 'gatsby'

// Static query for dynamic price fetching
const PricesQuery = () => {
  const { allDynamicPrice } = useStaticQuery(
    graphql`
      query {
        allDynamicPrice {
          edges {
            node {
              itemCode
              prices {
                CAD {
                  regular
                  renew
                }
                AUD {
                  regular
                  renew
                }
                MXN {
                  regular
                  renew
                }
                USD {
                  regular
                  renew
                }
              }
            }
          }
        }
      }
    `
  )

  const allDynamicPrices = []
  allDynamicPrice.edges.forEach(priceItem => {
    allDynamicPrices.push(priceItem.node)
  })

  return allDynamicPrices
}

const CapitalizeCurrencyKeys = price => {
  Object.keys(price).forEach(currency => {
    if (
      currency === 'cad' ||
      currency === 'usd' ||
      currency === 'mxn' ||
      currency === 'aud'
    ) {
      const value = price[currency]
      delete price[currency]
      price[currency.toUpperCase()] = value
    }
  })
  return price
}

const RenderStaticPrices = price => {
  const staticPrices = CapitalizeCurrencyKeys(price)
  return staticPrices
}

const GetCurrencies = (price, allDynamicPrices) => {
  if (!price) return null

  if (price.itemCode) {
    const item = allDynamicPrices.find(dp => dp.itemCode === price.itemCode)
    if (!item) {
      return RenderStaticPrices(price)
    }
    return item.prices
  }
  return RenderStaticPrices(price)
}

const DisplayPrice = (itemPackage, currentCode, allPrices) =>
  Price.getDisplayPrice(
    GetCurrencies(itemPackage?.price, allPrices),
    currentCode.toUpperCase()
  )

export {
  CapitalizeCurrencyKeys,
  RenderStaticPrices,
  GetCurrencies,
  DisplayPrice,
  PricesQuery,
}
