import React from 'react'
import { Box, Text, Icon, Badge, swiftTheme } from '@rebeldotcom/components'
import PropTypes from 'prop-types'

export const STATUSES = {
  NEW: 'New',
  ACTIVE: 'Active',
  PENDING: 'PendingCancellation',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted',
  ACTIONREQUIRED: 'Error',
  MIGRATING: 'Migrating',
  MIGRATIONREADY: 'MigrationReady',
  PENDINGMIGRATION: 'PendingMigration',
  RESETPASSWORD: 'ResetPassword',
  MIGRATIONCOMPLETE: 'MigrationComplete',
  SUSPENDED: 'Suspended',
}
const propTypes = {
  status: PropTypes.string.isRequired,
}

const AccountStatusBadge = ({ status }) => {
  const { colors } = swiftTheme
  const { success, warning, neutral, supporting, secondary } = colors
  let badgeColor
  let icon
  let width = 16
  let label = `${status} subscription`
  switch (status) {
    case STATUSES.NEW:
      badgeColor = supporting.strongCyan
      icon = 'star'
      break
    case STATUSES.ACTIVE:
      /* eslint-disable prefer-destructuring */
      badgeColor = success[500]
      icon = 'checkmark-fill'
      break
    case STATUSES.ACTIONREQUIRED:
      badgeColor = warning[500]
      width = 13
      icon = 'solid-padlock'
      label = 'Action Required'
      break
    case STATUSES.PENDING:
      badgeColor = neutral[500]
      width = 13
      icon = 'solid-padlock'
      label = 'Pending Cancellation'
      break
    case STATUSES.MIGRATING:
      badgeColor = warning[500]
      width = 13
      icon = 'solid-padlock'
      label = 'Upgrade In Progress'
      break
    case STATUSES.MIGRATIONREADY:
      badgeColor = secondary[500]
      width = 13
      icon = 'checkmark-fill'
      label = 'Upgrade Ready'
      break
    case STATUSES.SUSPENDED:
      badgeColor = secondary[500]
      width = 13
      icon = 'lightning-charge'
      label = status
      break
    default:
      badgeColor = success[500]
      break
    /* eslint-enable prefer-destructuring */
  }

  return (
    <Box display={['flex', 'flex', 'flex']} flexDirection="column">
      <Badge
        bg={badgeColor}
        css={`
          white-space: nowrap;
        `}
        mt={[2, 0]}
        mx={[0, 2]}
        px={2}
      >
        <Box height="100%">
          <Text textTransform="capitalize" variant="milli">
            {label}
          </Text>
          <Icon
            alignItems="center"
            color="white"
            css={`
              position: relative;
              bottom: 1px;
            `}
            height="100%"
            ml={[2]}
            name={icon}
            width={width}
          />
        </Box>
      </Badge>
    </Box>
  )
}

AccountStatusBadge.propTypes = propTypes

export { AccountStatusBadge }
