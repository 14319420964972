import React from 'react'
import {
  AlertInline,
  Section,
  Stack,
  Box,
  Text,
  Heading,
  Skeleton,
  swiftTheme,
  Button,
  TableHeader,
  TableHeaderLabel,
} from '@rebeldotcom/components'
import { isMobile } from 'react-device-detect'
import {
  useOpenExchangeService,
  ErrorBoundary,
  isInternic,
} from '@rebeldotcom/ui'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Content } from '../../../content'
import EmailItem from './ox-email-item'
import DomainHeader from './ox-email-section-header'
import STRINGS from './ox.strings'
import OXAddEmail from './ox-add-email'
import OXAddPackageBanner from './ox-add-package-banner'
import { STATUSES } from '../../../compound/account-status-badge'

const { colors } = swiftTheme
const gridFormat = {
  gridGap: ['10px'],
  gridTemplateColumns: ['1fr 1fr 1.1fr 0.5fr'],
  gridMargin: 3,
}

export const SimpleDivider = styled.hr`
  display: block;
  border: 0;
  border-top: 1px solid #ccc;
  margin: '1em 0';
  padding: 0;
`

export const isActiveUser = user =>
  user?.userStatus === STATUSES.ACTIVE ||
  user?.userStatus === STATUSES.PENDINGMIGRATION ||
  user?.userStatus === STATUSES.RESETPASSWORD ||
  user?.userStatus === STATUSES.MIGRATIONCOMPLETE

const DisplayAccountProps = {
  domain: PropTypes.object.isRequired,
}

const DisplayActiveAccounts = ({ domain }) => (
  <Stack mb={[3, 4]}>
    <DomainHeader domain={domain} />
    {domain?.subscriptionStatus === STATUSES.SUSPENDED ? (
      <AlertInline
        body={<Content variant="kilo">{STRINGS.suspendedError}</Content>}
        mb={3}
        mt={1}
        notificationTitle="error"
        showTitle={false}
        variant="error"
        width="100%"
      />
    ) : (
      <>
        {!isMobile && !domain?.actionRequired && (
          <TableHeader gridFormat={gridFormat}>
            <TableHeaderLabel label={STRINGS.addressHeader} />
            <TableHeaderLabel label={STRINGS.diskSpaceHeader} />
            <TableHeaderLabel label={STRINGS.managePassword} ml={[0, 3]} />
            <TableHeaderLabel
              label={STRINGS.emailSettings}
              mr={[0, 3]}
              textAlign="right"
            />
          </TableHeader>
        )}
        {domain?.users &&
          domain?.users
            .filter(user => isActiveUser(user))
            .map((user, userIndex) => (
              <EmailItem
                key={user.oxUserId}
                domain={domain}
                index={userIndex}
                user={user}
              />
            ))}
        <OXAddEmail domain={domain} />
      </>
    )}
  </Stack>
)

DisplayActiveAccounts.propTypes = DisplayAccountProps

const OXEmailManager = () => {
  const { fetchError, accounts, providerLoading } = useOpenExchangeService()

  const sortedAccounts = accounts
    .filter(domain => domain.accountStatus !== STATUSES.DELETED)
    .sort((d1, d2) => {
      switch (d2.accountStatus) {
        case STATUSES.MIGRATING:
          if (d1.accountStatus === STATUSES.MIGRATING) return 0
          return 1
        case STATUSES.MIGRATIONREADY:
          if (d1.accountStatus === STATUSES.MIGRATING) return -1
          if (d1.accountStatus === STATUSES.MIGRATIONREADY) return 0
          return 1
        case STATUSES.ACTIVE:
        case STATUSES.PENDING:
          if (
            d1.accountStatus === STATUSES.MIGRATING ||
            d1.accountStatus === STATUSES.MIGRATIONREADY
          ) {
            return -1
          }
          /* Show newly created ox accounts at the top */
          return d2.oxAccountId - d1.oxAccountId
        default:
          return 0
      }
    })

  const openWebmail = () =>
    window.open(
      isInternic ? 'https://mail.internic.ca/' : 'https://mail.rebel.com/'
    )
  const openEmailSetup = () =>
    window.open(
      isInternic
        ? 'https://www.internic.ca/email/smart-setup'
        : 'https://www.rebel.com/email/smart-setup'
    )

  return (
    <ErrorBoundary location="email-manager">
      <Section
        containerProps={{
          maxWidth: 'containers.lg',
        }}
        mb={3}
        pb={[4, 5]}
        pt={[2, 3]}
        px={[3, 4]}
      >
        <OXAddPackageBanner />
        <Stack width="100%">
          <Box flexDirection="column" mb={2} width="100%">
            <Heading textAlign="left" variant={['gigaBold', 'teraBold']}>
              {STRINGS.sectionTitle}
            </Heading>
            <Box
              flexDirection={['column', 'row']}
              justifyContent="space-between"
              mb={2}
            >
              <Text alignSelf="flex-end" variant="pound">
                {STRINGS.subtitle}
              </Text>
              <Box flexDirection={['column', 'row']}>
                <Button
                  color={isInternic ? 'blueDark' : 'deepKoamaru'}
                  mb={[2, 0]}
                  mr={3}
                  onClick={openWebmail}
                  variant="inverse"
                >
                  {STRINGS.webMail}
                </Button>
                <Button
                  color={isInternic ? 'blueDark' : 'deepKoamaru'}
                  mr={3}
                  onClick={openEmailSetup}
                  variant="inverse"
                >
                  {STRINGS.emailSetup}
                </Button>
              </Box>
            </Box>
            <SimpleDivider color="#d7d7d7" />
          </Box>
          {providerLoading ? (
            <Skeleton height={['20rem', '40rem']} />
          ) : (
            <Box width="100%">
              <Stack width="100%">
                {fetchError ? (
                  <AlertInline
                    body={STRINGS.fetchError}
                    notificationTitle="error"
                    showTitle
                    variant="error"
                  />
                ) : (
                  <>
                    {sortedAccounts && sortedAccounts.length < 1 ? (
                      <AlertInline
                        body={STRINGS.emptyManager}
                        showTitle={false}
                        variant="primary"
                      />
                    ) : (
                      sortedAccounts &&
                      sortedAccounts.map((domain, idx) => {
                        return (
                          <DisplayActiveAccounts
                            key={`activated-${domain}-${idx}`}
                            domain={domain}
                            position={idx}
                          />
                        )
                      })
                    )}
                  </>
                )}
              </Stack>
            </Box>
          )}
        </Stack>
      </Section>
    </ErrorBoundary>
  )
}

export default OXEmailManager
