import React from 'react'
import { Box, Stack, Text, Selector, Icon, Grid } from '@rebeldotcom/components'
import { useSessionService, isInternic } from '@rebeldotcom/ui'
import PropTypes from 'prop-types'
import { DisplayPrice } from '../../../utilities/pricing'
import { Content } from '../../content'
import { SimpleDivider } from '../../my-account/email-manager/ox-email/ox-email-manager'

const propTypes = {
  allDynamicPrices: PropTypes.array.isRequired,
  currentPackageID: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  packages: PropTypes.array.isRequired,
  selectedPackageId: PropTypes.number,
}

const defaultProps = {
  currentPackageID: null,
  selectedPackageId: null,
}

const ConfiguratorSelector = ({
  allDynamicPrices,
  packages,
  selectedPackageId,
  onChange,
  currentPackageID,
}) => {
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()

  // Create configurator options from data
  const packageOptions = packages.map(p => {
    return {
      id: p.packageId,
      content: (
        <Stack
          key={p.itemCode}
          alignItems="stretch"
          justifyContent="center"
          minWidth="15rem"
          my={[3, 2]}
        >
          <Text textAlign="center" variant="gigaBold">
            <>{p.title}</>
            {currentPackageID === p.packageId && (
              <Text color="rgba(0,0,0,0.5)" textAlign="center" variant="kilo">
                (Current Package)
              </Text>
            )}
          </Text>
          {p.price ? (
            <Box alignItems="flex-end" justifyContent="center" mt={2}>
              <Text
                data-testid={p.price.itemCode}
                fontFamily="title"
                lineHeight="1.2"
                textAlign="center"
                variant="mega"
              >
                {`${DisplayPrice(p, currentCode, allDynamicPrices)}`}
              </Text>
              {p.price.suffix ? (
                <Text lineHeight="1.2" ml={1} variant="milli">
                  {p.price.suffix}
                </Text>
              ) : null}
            </Box>
          ) : null}
          {p.features && p.features.length > 0 && (
            <Stack mt={3} width="100%">
              <Grid
                as="ul"
                css={`
                  list-style-position: outside;
                  list-style: none;
                  gap: 0.5em;
                  line-height: 1.3em;
                  margin-left: auto;
                  margin-right: auto;
                `}
              >
                <Box as="li">
                  <Text variant="milliBold">WHAT&apos;S INCLUDED</Text>
                  <SimpleDivider />
                </Box>
                {p.features.map((feature, index) => {
                  return (
                    <Box
                      key={`feature-${index}-${feature}`}
                      alignItems="center"
                      as="li"
                      mb={1}
                      mt={index === 0 ? 2 : 0}
                    >
                      <Icon
                        aria-hidden="true"
                        color={isInternic ? 'blueDark' : 'deepKoamaru'}
                        name="checkmark-fill"
                      />
                      <Content ml={2} variant="pound">
                        {feature}
                      </Content>
                    </Box>
                  )
                })}
              </Grid>
            </Stack>
          )}
        </Stack>
      ),
      testId: p.id,
    }
  })

  return (
    <Selector
      css={`
        input[type='radio'] {
          & + div {
            border-width: 3px;
            border-radius: 6px;
            cursor: pointer;
            transition: 0.2s;
          }
          &:checked + div {
            border-color: #3b99fc;
          }
        }
      `}
      mx={[3, 0]}
      onChange={onChange}
      options={packageOptions}
      selected={selectedPackageId}
    />
  )
}

ConfiguratorSelector.propTypes = propTypes
ConfiguratorSelector.defaultProps = defaultProps
export default ConfiguratorSelector
