import { isInternic } from '@rebeldotcom/ui'

const FEATURES = [
  'Huge 30GB storage per mailbox',
  'Powerful Inbox',
  'AI enhanced anti-spam',
  'Docs, Sheets & Presentations',
  'Encrypted email and file sharing',
  '100% uptime',
]

const BANNER_STRINGS = {
  badge: 'Try all features for 30 days',
  logo: 'Cloud Security+',
  heading: `Build your brand ${isInternic ? 'Internic' : 'Rebel'} Cloud Email!`,
  cta: 'Start Free Trial',
  finePrint: 'Renews at full price, change package at any time',
  domain: 'Domain',
  loadingCTA: 'Creating your email...',
}

export { BANNER_STRINGS, FEATURES }
