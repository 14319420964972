/* eslint-disable no-nested-ternary */
import * as React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from '@reach/router'
import { Box, Text, Icon, Link, Button } from '@rebeldotcom/components'

const propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      onClick: PropTypes.func,
      url: PropTypes.string,
    })
  ).isRequired,

  selectedCrumb: PropTypes.number.isRequired,
}

const Breadcrumbs = ({ selectedCrumb, crumbs, ...rest }) => {
  const getCrumbStyles = index => {
    return index === selectedCrumb
      ? {
          color: 'black',
          variant: 'poundBold',
          paddingTop: '2px',
        }
      : {
          color: 'greyDarker',
          variant: 'pound',
        }
  }

  return (
    <Box {...rest}>
      {crumbs.map(({ display, url, disabled, onClick }, index) => (
        <Box key={display} alignItems="center" ml={index > 0 ? 3 : 0}>
          {selectedCrumb === index || disabled ? (
            <Text {...getCrumbStyles(index)}>{display}</Text>
          ) : onClick ? (
            <Button onClick={onClick} variant="link">
              <Text {...getCrumbStyles(index)} textTransform="none">
                {display}
              </Text>
            </Button>
          ) : (
            <Link as={RouterLink} to={url}>
              <Text {...getCrumbStyles(index)}>{display}</Text>
            </Link>
          )}

          {index < crumbs.length - 1 ? (
            <Icon
              color="greyDarker"
              height="1rem"
              ml={3}
              name="arrow-right"
              title="next breadcrumb"
              width="1rem"
            />
          ) : null}
        </Box>
      ))}
    </Box>
  )
}

Breadcrumbs.propTypes = propTypes

export default Breadcrumbs
