import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Message, Text, Button, Heading } from '@rebeldotcom/components'
import {
  get,
  cleanDomain,
  addToCart,
  SearchBar,
  UserAuth,
  Dropdown,
  post,
  RESELLER,
} from '@rebeldotcom/ui'
import { getAddToCartWithDomainEndpoint } from '../../../utilities/endpoints'

const getResellerBaseURL = () => {
  const Prod = {
    endpoint: `https://www.${RESELLER}/`,
  }

  const Dev = {
    endpoint: `https://dev.${RESELLER}/`,
  }

  const Local = {
    endpoint: `https://local.${RESELLER}/`,
  }

  const { endpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && Local) || (__DEV__ && Dev) || (__PROD__ && Prod)

  return endpoint
}

const strings = {
  title:
    'Your stunning website is waiting. Start by finding the perfect domain.',
  search_bar_input: '',
  description: '',
  questionExistingDomain: 'Do you have a domain with us?',
  existingDomain: 'Add this package through your account',
  newCustomer: 'New customers:',
  addNewDomain: 'Add a new domain to continue:',
  placeholder: 'Search available domains',
  success_message: 'The domain and package has been added to your cart.',
  userDomains: 'Welcome back! Which domain would you like to connect?',
}

const propTypes = {
  addDomainProductPromoPackageToCart: PropTypes.object,
  btnColor: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  searchLimitedTo: PropTypes.arrayOf(PropTypes.string),
  selectedPackage: PropTypes.shape({
    addToCartPath: PropTypes.string,
    amount: PropTypes.number,
    clickHerePath: PropTypes.string,
    id: PropTypes.string,
    itemCode: PropTypes.string,
    itemDescription: PropTypes.string,
    packageId: PropTypes.number,
    product: PropTypes.string,
    productCategory: PropTypes.string,
    productType: PropTypes.number,
    returnPath: PropTypes.string,
    source: PropTypes.string,
  }),
  showOnlySearch: PropTypes.bool,
}

const defaultProps = {
  // content: 'product copy goes here',
  addDomainProductPromoPackageToCart: null,
  btnColor: 'greenDark',
  placeholder: strings.placeholder,
  searchLimitedTo: [],
  selectedPackage: {},
  showOnlySearch: false,
}

const DomainProductSearch = ({
  id,
  selectedPackage,
  user,
  newDomain,
  domainList,
  showOnlySearch,
  searchLimitedTo,
  placeholder,
  btnColor,
  addDomainProductPromoPackageToCart,
}) => {
  const [state, setState] = useState({
    searching: false,
    error: '',
    domain: null,
  })

  const [selectedDomain, setDomain] = useState()

  const clearSearch = () => {
    setState({
      searching: false,
      error: '',
      domain: null,
    })
  }

  const getLoadedAddToCartUrl = url => {
    const { domain } = state

    const parts = domain.name.split('.')
    const name = parts.shift()
    const tld = parts.length ? parts.join('.') : ''

    return url
      .replace('{{domain}}', domain.name)
      .replace('{{name}}', name)
      .replace('{{tld}}', tld)
  }

  const onSubmit = async () => {
    const { domain } = state

    if (!domain) return

    if (addDomainProductPromoPackageToCart !== null) {
      // do api call
      try {
        addDomainProductPromoPackageToCart.DomainName = domain.name

        const result = await post(
          `${getResellerBaseURL()}${
            addDomainProductPromoPackageToCart.PromoEndpoint
          }`,
          addDomainProductPromoPackageToCart
        )

        if (!result.finished) {
          if (result.errorMessage) {
            setState({
              ...state,
              error: result.errorMessage,
            })
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        setState({
          ...state,
          error: 'There was an issue adding the package to the cart',
        })
        return
      }

      window.location = `/MyAccount/ShoppingCart.aspx`
    } else {
      try {
        const {
          addToCartPath,
          amount,
          itemCode,
          itemDescription,
          product,
          productCategory,
          returnPath,
          source,
        } = selectedPackage

        const result = await addToCart({
          addToCartPath: getLoadedAddToCartUrl(addToCartPath),
          amount,
          itemCode,
          itemDescription,
          product,
          productCategory,
          returnPath,
          source,
          method: 'post',
        })
        if (!result.valid) {
          setState({
            ...state,
            error: result.error.response.data.message,
          })
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('error', err)
      }
    }
  }

  const onSubmitExisting = async () => {
    if (!selectedDomain) return
    const cartBody = {
      productType: selectedPackage.productType,
      packageID: selectedPackage.packageId,
      itemName: selectedPackage.itemCode,
      domainName: selectedDomain.value,
    }

    const response = await post(getAddToCartWithDomainEndpoint(), cartBody)
    if (response && window) {
      window.location = selectedPackage.returnPath
    }
  }

  const domainSearch = async input => {
    input = cleanDomain(input)
    let keyword = input.toLowerCase().replace(/[^a-z0-9-\.]/g, '')

    let getString = getResellerBaseURL()
    getString += `SearchData.ashx?a=c&kw=${keyword}`

    if (searchLimitedTo.length !== 0) {
      const TLDMessage =
        searchLimitedTo.length > 1
          ? `Your TLD must be one of the following ${searchLimitedTo.join(
              ', '
            )}.`
          : `Your TLD must be a .${searchLimitedTo[0]} domain.`

      let TLD = keyword.split('.').pop()

      if (keyword.split('.').length === 1) {
        ;[TLD] = searchLimitedTo
        getString += `.${TLD}`
        keyword += `.${TLD}`
      }

      if (!searchLimitedTo.includes(TLD)) {
        setState({
          ...state,
          error: TLDMessage,
          searching: false,
        })
        return
      }
    }

    setState({
      error: '',
      domain: null,
      searching: true,
    })

    try {
      const response = await get(getString)
      if (!response.data.Results.length) {
        setState({
          ...state,
          error: 'The search keyword needs to be a valid domain name.',
          searching: false,
        })
        return
      }

      const result = response.data.Results[0]
      if (result.Status !== 'Available') {
        setState({
          ...state,
          error: 'This domain is not available.',
          searching: false,
        })
        return
      }

      setState({
        ...state,
        error: '',
        domain: { name: keyword, price: result.Price },
      })
    } catch (err) {
      setState({
        ...state,
        error: 'Something went wrong!',
        searching: false,
      })
    }
  }
  const domainMessage =
    state.domain &&
    (showOnlySearch
      ? `${state.domain.name} is available!`
      : `${state.domain.name} is available for ${state.domain.price}`)

  const searchHtml = (
    <Stack
      bg="white"
      color="black"
      mb={showOnlySearch ? 4 : null}
      mt={showOnlySearch ? 4 : null}
      pb={showOnlySearch ? null : 4}
      pt={showOnlySearch ? null : 3}
      px={showOnlySearch ? null : 5}
      width="100%"
    >
      {!showOnlySearch && strings && <p>{strings.description}</p>}

      {!newDomain && !user && !showOnlySearch ? (
        <Stack
          bg="white"
          color="black"
          maxWidth="containers.md"
          pb={3}
          pt={3}
          width="100%"
        >
          <UserAuth id="account" showHeading stopRedirect />
        </Stack>
      ) : null}

      {!newDomain && user && !showOnlySearch ? (
        <Stack
          bg="white"
          color="black"
          maxWidth="containers.md"
          pb={3}
          pt={3}
          width="100%"
        >
          <Dropdown
            id="select-domain-dropdown"
            onChange={setDomain}
            options={domainList}
            placeholder="Choose domain..."
            title={strings.userDomains}
            width="100%"
          />

          <Button
            color="greenDark"
            id="add-existing-domain-to-cart"
            my={3}
            onClick={onSubmitExisting}
          >
            <Text variant="mega">Add to Cart and Checkout</Text>
          </Button>
        </Stack>
      ) : null}

      {strings && newDomain && (
        <Stack mb={2}>
          <Heading variant="giga">{strings.addNewDomain}</Heading>
        </Stack>
      )}
      {!state.domain && (newDomain || showOnlySearch) && (
        <SearchBar
          autoFocus
          bordered
          btnColor={btnColor}
          defaultButton
          id={`${id}-search`}
          isLoading={state.searching}
          onSearch={domainSearch}
          placeholder={placeholder}
          width="100%"
        />
      )}
      {state.domain && (
        <Stack alignItems="center">
          {domainMessage && (
            <Text
              color={!showOnlySearch ? 'success' : 'sunflower'}
              mb={2}
              variant="petaBold"
            >
              {domainMessage}
            </Text>
          )}
          <Button
            background={btnColor}
            color={btnColor}
            id="add-domain-to-cart"
            my={3}
            onClick={onSubmit}
          >
            <Text variant="mega">Add to Cart and Checkout</Text>
          </Button>
          <Button
            color={!showOnlySearch ? '' : 'black'}
            id="search-again"
            onClick={clearSearch}
            variant="link"
          >
            <Text color={!showOnlySearch ? '' : 'black'} variant="mega">
              Search for a different domain
            </Text>
          </Button>
        </Stack>
      )}
      {state.error && (newDomain || showOnlySearch) && (
        <Message mt={3} variant="error">
          {state.error}
        </Message>
      )}
    </Stack>
  )

  return searchHtml
}

DomainProductSearch.propTypes = propTypes
DomainProductSearch.defaultProps = defaultProps

export { DomainProductSearch }
