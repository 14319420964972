import React, { useState } from 'react'
import {
  Stack,
  Heading,
  Text,
  Button,
  AlertInline,
  Skeleton,
} from '@rebeldotcom/components'
import {
  isInternic,
  RESELLER,
  cleanDomain,
  SearchBar,
  get,
} from '@rebeldotcom/ui'
import PropTypes from 'prop-types'
import { STRINGS } from './existing-domain'

const btnColor = isInternic ? 'blueDark' : 'magenta'
const propTypes = {
  placeholder: PropTypes.string,
  searchLimitedTo: PropTypes.array,
  selectedPackage: PropTypes.shape({
    addToCartPath: PropTypes.string,
    amount: PropTypes.number,
    clickHerePath: PropTypes.string,
    id: PropTypes.string,
    itemCode: PropTypes.string,
    itemDescription: PropTypes.string,
    packageId: PropTypes.number,
    product: PropTypes.string,
    productCategory: PropTypes.string,
    productType: PropTypes.number,
    returnPath: PropTypes.string,
    source: PropTypes.string,
  }),
}
const defaultProps = {
  placeholder: 'Search for a domain...',
  searchLimitedTo: [],
  selectedPackage: {},
}
const getResellerBaseURL = () => {
  const Prod = {
    endpoint: `https://www.${RESELLER}/`,
  }

  const Dev = {
    endpoint: `https://dev.${RESELLER}/`,
  }

  const Local = {
    endpoint: `https://local.${RESELLER}/`,
  }

  const { endpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && Local) || (__DEV__ && Dev) || (__PROD__ && Prod)

  return endpoint
}

/**
 * NEW DOMAIN SEARCH
 * This component currently not in active use, but it may become useful in the future.
 * With that said, it may need more work because the state in which it was built may be incomplete
 * It was initially for Cloud Email, but the idea was scrapped.
 * So here lays the code that wasn't completed
 * @param {} param0
 * @returns
 */
const NewDomain = ({ placeholder, searchLimitedTo }) => {
  const [state, setState] = useState({
    error: '',
    domain: null,
  })
  const [loading, setLoading] = useState(false)

  const clearSearch = () => {
    setState({
      error: '',
      domain: null,
    })
  }

  const onSubmit = async () => {
    const { domain } = state

    if (!domain) return
    try {
      // TD: Add the add-to-cart endpoint Brent is working on here.
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error', err)
    }
  }

  const domainSearch = async input => {
    clearSearch()
    setLoading(true)
    input = cleanDomain(input)
    const keyword = input.toLowerCase().replace(/[^a-z0-9-\.]/g, '')

    let getString = getResellerBaseURL()
    getString += `SearchData.ashx?a=c&kw=${keyword}`

    if (searchLimitedTo.length !== 0) {
      const TLDMessage = STRINGS.TLDMessage(searchLimitedTo)

      const TLD = keyword.split('.').pop()

      if (!searchLimitedTo.includes(TLD)) {
        setState({
          ...state,
          error: TLDMessage,
        })
        return
      }
    }

    try {
      const response = await get(getString)
      if (!response.data.Results.length) {
        setState({
          error: STRINGS.newDomainSearchError,
          domain: null,
        })
        setLoading(false)

        return
      }

      const result = response.data.Results[0]
      if (result.Status !== 'Available') {
        setState({
          error: STRINGS.newDomainUnavailable,
          domain: null,
        })
        setLoading(false)

        return
      }

      setState({
        error: '',
        domain: { name: keyword, price: result.Price },
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setState({
        error: STRINGS.genericError,
        domain: null,
      })
    }
  }

  const domainMessage =
    state.domain &&
    STRINGS.newDomainAvailable(state.domain.name, state.domain.price)

  return (
    <Stack width="100%">
      <Heading mb={2} variant="giga">
        {STRINGS.newDomainHeader}
      </Heading>
      <SearchBar
        autoFocus
        autoSearch={false}
        bordered
        btnColor={btnColor}
        defaultButton
        id="domain-search"
        isLoading={false}
        onSearch={domainSearch}
        placeholder={placeholder}
        width="100%"
      />

      {loading && <Skeleton height="3rem" />}
      {state.domain && (
        <Stack justifyContent="center">
          {domainMessage && (
            <AlertInline
              body={domainMessage}
              icon="pin"
              justifyContent="center"
              mb={2}
              mt={3}
              showTitle={false}
              variant="success"
              width="100%"
            />
          )}
          <Button
            alignSelf="center"
            background={btnColor}
            color={btnColor}
            id="add-domain-to-cart"
            my={3}
            onClick={onSubmit}
            width="50%"
          >
            <Text variant="mega">{STRINGS.addToCart}</Text>
          </Button>
          <Button id="search-again" onClick={clearSearch} variant="link">
            <Text variant="mega">{STRINGS.searchForDomain}n</Text>
          </Button>
        </Stack>
      )}

      {state.error && (
        <AlertInline
          body={state.error}
          my={2}
          showTitle={false}
          variant="error"
        />
      )}
    </Stack>
  )
}

NewDomain.propTypes = propTypes
NewDomain.defaultProps = defaultProps
export { NewDomain }
