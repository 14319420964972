import React from 'react'
import { Text, Box, Grid, Icon, Stack } from '@rebeldotcom/components'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

const propTypes = {
  first: PropTypes.bool,
  option: PropTypes.shape({
    color: PropTypes.string,
    display: PropTypes.any,
    icon: PropTypes.shape({
      color: PropTypes.string,
      height: PropTypes.string,
      hideIcon: PropTypes.bool,
      name: PropTypes.string,
      width: PropTypes.string,
    }),
    iconText: PropTypes.any,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.any.isRequired,
  }).isRequired,
}

const defaultProps = {
  first: false,
}

const SettingsOption = ({ option, first }) => {
  // Going to have to pass event to show -> Componentize each thing
  const itemButtonClick = event => {
    option.onClick(event)
  }

  return (
    <Box
      bg="white"
      border="1px solid #d7d7d7"
      borderTop={first || 'none'}
      color={option.color ? option.color : 'black'}
      onClick={e => itemButtonClick(e.target)}
      px={3}
      py={3}
      style={{ cursor: 'pointer' }}
      width="100%"
    >
      <Grid
        alignItems="center"
        gridTemplateColumns={['1fr 0.3fr', '1fr 1fr 0.3fr']}
        width="100%"
      >
        {isMobile ? (
          <Stack>
            <Text fontWeight="bold">{option.title}</Text>
            <Text>{option.display}</Text>
          </Stack>
        ) : (
          <>
            <Text fontWeight="bold">{option.title}</Text>
            <Text>{option.display}</Text>
          </>
        )}

        <Box justifyContent="flex-end">
          {option.iconText && (
            <Text mr={!option.hideIcon ? 3 : 0}>{option.iconText}</Text>
          )}
          <Box flexDirection="column" justifyContent="center" pb="4px">
            {!option.hideIcon && (
              <Icon
                color={option?.icon?.color ? option?.icon?.color : 'black'}
                height={option?.icon?.height ? option.icon.height : '1rem'}
                mr={1}
                name={option?.icon?.name ? option.icon.name : 'arrow-right'}
                title="next breadcrumb"
                width={option?.icon?.width ? option.icon.width : '1rem'}
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

SettingsOption.propTypes = propTypes
SettingsOption.defaultProps = defaultProps

export default SettingsOption
