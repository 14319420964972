import React from 'react'
import { PropTypes } from 'prop-types'
import { StyleReset, HtmlBlock, Text } from '@rebeldotcom/components'
import { containerVariants } from '../utilities/panels'

const propTypes = {
  children: PropTypes.string.isRequired,
  fontFamily: PropTypes.string,
  htmlCss: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  theme: PropTypes.oneOf(['light', 'dark']),
  variant: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

const defaultProps = {
  fontFamily: 'body',
  htmlCss: {},
  theme: 'light',
  variant: ['kilo', 'mega'],
}

const Content = ({
  theme,
  children,
  htmlCss,
  variant,
  fontFamily,
  ...props
}) => {
  return (
    <StyleReset>
      <Text
        color={containerVariants[theme || 'light'].text}
        flex="1"
        fontFamily={fontFamily}
        variant={variant}
      >
        <HtmlBlock {...props} css={htmlCss}>
          {children}
        </HtmlBlock>
      </Text>
    </StyleReset>
  )
}

Content.propTypes = propTypes
Content.defaultProps = defaultProps

export { Content }
