import { isInternic } from '@rebeldotcom/ui'

export const strings = {
  title: selectedPackage => {
    switch (selectedPackage) {
      case 1:
        return 'Cloud Basic'
      case 2:
        return 'Cloud Productivity'
      case 7:
        return 'Cloud Security+'
      default:
        return 'Cloud Basic'
    }
  },
  header: `${isInternic ? 'Internic' : 'Rebel'}  Cloud Email`,
  subtitle: 'Start by choosing the perfect address',
  addNewEmail: 'Add New Email Account',
  chooseCustomEmail: 'Choose your custom email and password',
  accountName: 'Account Name',
  password: 'Password',
  freeTrial: '30 Day Free Trial',
  terms: 'By clicking "Add Email" you are agreeing to purchase this product.',
  defaultErrorMessage:
    'Unable to purchase email. Please contact our Customer Support team for assistance.',
}
