import React from 'react'
import { Stack, Box, Heading, Text, Section } from '@rebeldotcom/components'
import { useOpenExchangeService } from '@rebeldotcom/ui'
import Breadcrumbs from '../../breadcrumbs'
import STRINGS from '../../my-account/email-manager/ox-email/ox.strings'
import { orderRouteAbsolute } from '../../my-account/email-manager/ox-email/breadcrumb-routes'

const CloudEmailChangePackageHeader = () => {
  const { navigate, currentContext } = useOpenExchangeService()
  const breadcrumbRoutes = [
    { display: 'Email Manager', onClick: () => navigate(orderRouteAbsolute) },
    {
      display: currentContext.domain,
      onClick: () => navigate(orderRouteAbsolute),
    },
    { display: 'Change Package', url: '' },
  ]

  return (
    <Section
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      pt={[2, 3]}
    >
      <Stack width={['100%', '64%', '54%']}>
        <Breadcrumbs
          crumbs={breadcrumbRoutes}
          justifyContent={['center', 'flex-start', 'flex-start']}
          mb={2}
          selectedCrumb={2}
        />
        <Box flexDirection="column" mb={2}>
          <Heading variant="teraBold">
            {`${STRINGS.changePackage} - ${currentContext.domain}`}
          </Heading>
          <Text alignSelf="flex-start" ml={1} variant="mega">
            {`Current package: ${STRINGS.packages[currentContext.itemCode]}`}
          </Text>
        </Box>
      </Stack>
    </Section>
  )
}

export { CloudEmailChangePackageHeader }
