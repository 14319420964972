import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, Heading, Text } from '@rebeldotcom/components'
import { Content } from './content'

const propTypes = {
  alignment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.oneOf(['left', 'center', 'right']),
  ]),
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div']),
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  maxWidth: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.shape({
    bottom: PropTypes.shape({
      text: PropTypes.string,
      textColor: PropTypes.string,
    }),
    main: PropTypes.shape({
      text: PropTypes.string,
      textColor: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      text: PropTypes.string,
      textColor: PropTypes.string,
    }),
    top: PropTypes.shape({
      bgColor: PropTypes.string,
      highlight: PropTypes.bool,
      text: PropTypes.string,
      textColor: PropTypes.string,
    }),
  }).isRequired,
  variant: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
}

const defaultProps = {
  alignment: 'center',
  as: 'h2',
  lineHeight: '1',
  maxWidth: 'containers.md',
  size: 'medium',
  theme: 'light',
  variant: ['teraBold', 'petaBold'],
}

const textAlignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  stretch: 'stretch',
}

const Title = ({
  as,
  size,
  title,
  alignment,
  maxWidth,
  variant,
  theme,
  lineHeight,
  ...rest
}) => {
  const getContentAlignment = () => {
    if (!Array.isArray(alignment)) return ['left', alignment]

    const contentAligment = [...alignment]
    contentAligment[0] = 'center'
    return contentAligment
  }

  const renderTopTitle = topTitle => {
    if (topTitle.highlight === true) {
      return (
        <Box>
          <Text
            bg={topTitle.bgColor}
            borderRadius="0.3rem"
            color={topTitle.textColor}
            fontFamily="body"
            fontWeight="bold"
            px={2}
            py={1}
            textAlign={getContentAlignment()}
            variant={['micro', 'kilo', 'milli']}
          >
            {topTitle.text}
          </Text>
        </Box>
      )
    }
    return (
      <Text
        color={topTitle.textColor}
        fontFamily="body"
        textAlign={getContentAlignment()}
        variant={['milli', 'pound', 'giga']}
      >
        {topTitle.text}
      </Text>
    )
  }

  return (
    <Stack {...rest} maxWidth={maxWidth}>
      {title.top && renderTopTitle(title.top)}
      {title.main && (
        <Heading as={as} textAlign={alignment}>
          <Content
            color={title.main.textColor}
            fontFamily="title"
            justifyContent={textAlignment[alignment]}
            lineHeight={lineHeight}
            textAlign={getContentAlignment()}
            theme={theme}
            variant={variant}
          >
            {title.main.text}
          </Content>
        </Heading>
      )}
      {title.bottom?.text && (
        <Content
          color={title.bottom.textColor}
          lineHeight="title"
          mt={3}
          textAlign={alignment}
          theme={theme}
          variant={['milli', 'giga', 'tera']}
        >
          {title.bottom.text}
        </Content>
      )}
      {title.subtitle?.text && (
        <Text
          color={title.subtitle.textColor}
          fontFamily="body"
          lineHeight="1.4"
          mt={3}
          textAlign={getContentAlignment()}
          variant={['micro', 'kilo', 'mega']}
        >
          {title.subtitle.text}
        </Text>
      )}
    </Stack>
  )
}

Title.propTypes = propTypes
Title.defaultProps = defaultProps

export { Title }
