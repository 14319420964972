import React, { useState } from 'react'
import { PropTypes } from 'prop-types'

import { Box, Stack, Input, Button, AlertInline } from '@rebeldotcom/components'
import { addToCart, cleanDomain } from '@rebeldotcom/ui'

/**
 * Config for Marketgoo, SiteLock
 */

const propTypes = {
  addButtonContent: PropTypes.string.isRequired,
  selectedPackage: PropTypes.shape({
    addToCartPath: PropTypes.string,
    amount: PropTypes.number,
    id: PropTypes.string,
    itemCode: PropTypes.string,
    itemDescription: PropTypes.string,
    product: PropTypes.string,
    productCategory: PropTypes.string,
    returnPath: PropTypes.string,
    source: PropTypes.string,
  }),
}

const defaultProps = {
  selectedPackage: {},
}

const DomainProduct = ({ addButtonContent, selectedPackage }) => {
  const [domain, setDomain] = useState('')

  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmit = async () => {
    const {
      addToCartPath,
      amount,
      itemCode,
      itemDescription,
      product,
      productCategory,
      returnPath,
      source,
    } = selectedPackage

    if (!domain || !itemCode) return

    const result = await addToCart({
      addToCartPath: addToCartPath.replace('{{domain}}', domain),
      amount,
      itemCode,
      itemDescription,
      product,
      productCategory,
      returnPath,
      source,
    })
    if (!result.valid) {
      if (result?.error?.message) {
        setErrorMessage(result.error.message)
      } else {
        setErrorMessage(
          'Something went wrong!  Try again or call our support team.'
        )
      }
    }
  }

  return (
    <Stack mt={4} mx={[2, 0]} width={['100%', '35%']}>
      <Box>
        <Input
          css={`
            input {
              padding-right: 0;
            }
          `}
          disabled
          id="www"
          placeholder="www."
          width={['22%', '15%']}
        />
        <Input
          id="domain-input"
          onChange={({ target: { value } }) => {
            setErrorMessage('')
            setDomain(cleanDomain(value))
          }}
          placeholder="yourdomain.com"
          value={domain}
        />
      </Box>
      <Box justifyContent="flex-start" mt={4}>
        <Button
          color="blueDark"
          id="add-product-domain"
          mr={2}
          onClick={onSubmit}
          variant="solid"
        >
          {addButtonContent}
        </Button>
      </Box>
      {errorMessage && (
        <AlertInline
          body={errorMessage}
          mt={3}
          notificationTitle="error"
          variant="error"
        />
      )}
    </Stack>
  )
}

DomainProduct.propTypes = propTypes
DomainProduct.defaultProps = defaultProps

export { DomainProduct }
