import React from 'react'
import { Box, Heading, AlertInline, Text } from '@rebeldotcom/components'
import PropTypes from 'prop-types'

const propTypes = {
  content: PropTypes.shape({
    error: PropTypes.string,
    header: PropTypes.string,
    question: PropTypes.string,
    success: PropTypes.string,
    target: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  showTypeContent: PropTypes.bool,
  subheaderComponent: PropTypes.node,
  type: PropTypes.string,
}

const defaultProps = {
  showTypeContent: false,
  subheaderComponent: null,
  type: 'warning',
}

const ConfirmationContent = ({
  content,
  subheaderComponent,
  type,
  showTypeContent,
  ...props
}) => {
  return (
    <Box flexDirection="column" {...props}>
      <Heading mb={3} variant="petaBold">
        {content.header}
      </Heading>
      {subheaderComponent}
      <Text>
        {content.question} {content.target}
      </Text>
      {showTypeContent && type && content[type] && (
        <AlertInline
          body={content[type]}
          mt={3}
          notificationTitle={type}
          variant={type}
        />
      )}
    </Box>
  )
}

ConfirmationContent.propTypes = propTypes
ConfirmationContent.defaultProps = defaultProps

export default ConfirmationContent
