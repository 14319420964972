import React, { useEffect, useState } from 'react'
import {
  Stack,
  Button,
  Box,
  Text,
  Icon,
  swiftTheme,
  PasswordStrengthBar,
  Spinner,
} from '@rebeldotcom/components'
import { isInternic, Dropdown, get, post, tracker } from '@rebeldotcom/ui'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { accountDomains } from '@rebeldotcom/ui/src/configs/api'
import { createStyles, ModInput } from './ox-add-package-banner.styles'
import { BANNER_STRINGS } from './ox-add-package-banner.strings'
import {
  ANALYTICS_EVENTS,
  PACKAGE_ITEM_CODES,
  USERNAME_REGEX,
} from '../ox.strings'
import { getOXBuyEmailEndpoints } from '../../../../../utilities/endpoints'

const { colors } = swiftTheme

const createDropdownOption = (username, domain) => ({
  label: domain,
  component: (
    <Box
      css={`
        flex-flow: wrap;
      `}
      justifyContent="flex-end"
    >
      <Text mr={1} variant="poundBold">
        {username}
      </Text>
      <Text>@ {domain}</Text>
    </Box>
  ),
})

const FreeTrialFormProps = {
  setError: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
}

const FreeTrialForm = ({ setError, setErrorMessage }) => {
  const [score, setScore] = useState(0)
  const [loading, setLoading] = useState(false)
  const [domainsLoading, setDomainsLoading] = useState(false)
  const [domains, setDomains] = useState([])

  const getDomainsWithoutCloudEmail = async () => {
    setDomainsLoading(true)
    try {
      const { status, data } = await get(accountDomains)

      if (status === 200) {
        const domainNames = Object.entries(data.domains)
          .filter(d => {
            const assets = d[1]
            return !assets.email
          })
          .map(d => d[0])

        setDomains(domainNames)
      }
      setDomainsLoading(false)
    } catch (err) {
      setDomainsLoading(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    return getDomainsWithoutCloudEmail()
  }, [])

  const handleSubmit = async (values, resetForm) => {
    setError(false)
    setLoading(true)

    tracker(ANALYTICS_EVENTS.cloudEmail.event, {
      type: ANALYTICS_EVENTS.cloudEmail.types.purplePanel,
      cloudEmail: {
        itemCode: PACKAGE_ITEM_CODES[7],
        email: `${values.username}@${values.domainName}`,
        domain: values.domainName,
        username: values.username,
      },
    })

    try {
      const payload = {
        packageId: 7,
        users: 1,
        username: values.username,
        domainName: values.domainName,
        password: values.password,
        ...values,
      }

      const response = await post(getOXBuyEmailEndpoints(), payload)
      if (response.data.success) {
        window.location = `/my-account/email-manager/${response.data.result.contextId}/dns-setup`
      }

      /*
	  temporary removal for ui fix
      const resp = await onNewOX({
        packageId: 7,
        ...values,
      })
	
      if (!resp) throw Error()
	  */

      resetForm()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setErrorMessage(err?.response?.data?.Message)
      setError(true)
    }
  }

  const validate = values => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Required'
    }

    if (values.username && !USERNAME_REGEX.test(values.username)) {
      errors.username = 'Invalid username'
    }

    if (!values.domainName) {
      errors.domainName = 'Required'
    }

    if (score < 3) {
      errors.password = 'Required'
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      domainName: '',
      username: '',
    },
    validate,
    onSubmit: values => {
      return handleSubmit(values, formik.resetForm)
    },
  })

  const onChangePassword = e => {
    if (e.target) {
      formik.setFieldValue('password', e.target.value, false)
    }
  }

  const dropdownOptions = domains.map(domain =>
    createDropdownOption(formik.values.username, domain)
  )

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Box
        alignItems="baseline"
        display={['none', 'grid', 'grid']}
        flexDirection={['column']}
        gridGap="15px"
        gridTemplateColumns={['1fr 1.2fr 1fr 1fr']}
        mb={2}
        mt={3}
      >
        <Box flexDirection="column">
          <ModInput
            {...formik.getFieldProps('username')}
            borderColor={
              formik.touched.username && formik.errors.username
                ? colors.destructive[500]
                : ''
            }
            id="add-email-username"
            label="Name"
            mr={2}
            mt="1px"
            placeholder="eg. john, info"
            type="text"
          />
        </Box>
        <Box flexDirection="column">
          <Text color="white" variant="milliBold">
            {BANNER_STRINGS.domain}
          </Text>
          <Dropdown
            alignSelf="center"
            height={33}
            id="domain-dropdown"
            inputStyles={createStyles(
              formik.touched.domain && formik.errors.domain
            )}
            isClearable
            isLoading={domainsLoading}
            isSearchable
            label="Domain"
            onChange={value => {
              formik.setFieldValue('domainName', value ? value.label : '', true)
            }}
            options={dropdownOptions}
            padding="0"
            placeholder={
              domainsLoading ? 'Loading domains...' : 'Choose domain...'
            }
            width="100%"
          />
        </Box>
        <Box flexDirection="column">
          <ModInput
            id="add-email-password"
            placeholder="Enter Password"
            type="password"
            {...formik.getFieldProps('password')}
            autoComplete="new-password"
            borderColor={
              formik.touched.password && formik.errors.password
                ? colors.destructive[500]
                : ''
            }
            label="Password"
            name="disableAutoPass"
            onChange={onChangePassword}
          />
          <Box mt={2}>
            <PasswordStrengthBar
              borderRadius="5px"
              onChangeScore={s => setScore(s)}
              password={formik.values.password}
              showFeedback
              showLabels
            />
          </Box>
        </Box>
        <Box
          alignSelf="flex-start"
          flexDirection="column"
          justifyContent="flex-end"
          pt="20px"
        >
          <Button
            alignItems="center"
            color={isInternic ? 'greenDark' : 'magenta'}
            disabled={loading}
            height="37.99px"
            mt="1px"
            pb={1}
            type="submit"
            variant="solid"
          >
            <Icon
              aria-hidden="true"
              color="white"
              mb={1}
              name="cloud-email"
              title="start-free-trial"
              width="4rem"
            />

            {loading && (
              <Stack flexDirection="row" style={{ overflow: 'hidden' }}>
                <Text mr={2}>{BANNER_STRINGS.loadingCTA}</Text>
                <Box flexDirection="column">
                  <Spinner color="white" size="1.5rem" stroke=".4rem" />
                </Box>
              </Stack>
            )}
            {!loading && 'Start Free Trial'}
          </Button>
          <Box
            display={['flex', 'flex', 'flex']}
            justifyContent="center"
            mt={2}
            width="100%"
          >
            <Text fontSize="1rem">{BANNER_STRINGS.finePrint}</Text>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

FreeTrialForm.propTypes = FreeTrialFormProps

export default FreeTrialForm
