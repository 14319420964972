import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Heading,
  Button,
  Box,
  useModal,
  ModalProvider,
  Spinner,
} from '@rebeldotcom/components'
import { isInternic, useOpenExchangeService, useUserService } from '@rebeldotcom/ui'
import STRINGS from './ox.strings'
import ConfirmationContent from '../../../compound/confirmation-content'

const propTypes = {
  isSynchronous: PropTypes.bool,
  modalAction: PropTypes.shape({
    action: PropTypes.string.isRequired,
    contextId: PropTypes.number.isRequired,
    header: PropTypes.string,
    type: PropTypes.string.isRequired,
    userId: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  show: PropTypes.bool.isRequired,
}

const defaultProps = {
  isSynchronous: false,
  modalAction: null,
  onError: null,
  onSuccess: null,
}

const getContent = (action, type, header) => {
  switch (type) {
    case 'email':
      return action === 'restore'
        ? { ...STRINGS.restoreUser }
        : { ...STRINGS.deleteUser, header: STRINGS.deleteUser.header(header)}
    case 'package':
      return action === 'restore'
        ? { ...STRINGS.restoreAccount }
        : {
            ...STRINGS.deleteAccount,
            header: STRINGS.deleteAccount.header(header),
          }
    default:
      return { header: '', question: '', warning: '' }
  }
}

const OXDeleteRecoverModal = ({
  show,
  modalAction,
  onClose,
  onError,
  onSuccess,
  isSynchronous,
}) => {
  const { onDeleteRecover,  accounts } =
    useOpenExchangeService()
  const{ user } = useUserService()
  const { openModal, closeModal, isOpen, Modal } = useModal()
  const [responseType, setResponseType] = useState('warning')
  const [hideButtons, setHideButtons] = useState(false)
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [isLastUser, setIsLastUser] = useState(false)
  const content = getContent(
    modalAction.action,
    modalAction.type,
    modalAction.header
  )

  // When a close is manually fired (escape key, close button, cancel button, etc)
  const onManualClose = (loading = false) => {
    closeModal()
    onClose(loading)
    setResponseType('warning') // reset back to warning after closing modal
    setIsLastUser(false)

    if (isSynchronous) {
      setHideButtons(false)
      setShowLoadingSpinner()
    }
  }

  const handleConfirm = async () => {
    try {
      // If we're not waiting for the response (asynchronous),
      // then close the modal right away
      if (!isSynchronous) {
        onManualClose(true)
      } else {
        setShowLoadingSpinner(true)
      }
      const { action, contextId, userId } = modalAction
      const resp = await onDeleteRecover(action, contextId, userId)
      if (onSuccess && resp) onSuccess()
      if (onError && !resp) onError()

      // If we waited for the response of the call,
      // then keep the modal open until we get a respnse
      if (isSynchronous) {
        setResponseType(resp ? 'success' : 'error')
        // give the user the opportunity to try again on error
        setHideButtons(resp)
        setShowLoadingSpinner(false)
      }
    } catch (err) {
      const isAlreadyDeleted = err.message.includes('status code 400')
      if (onError) onError(isAlreadyDeleted)
      if (isSynchronous) setResponseType('error')
      setShowLoadingSpinner(false)
    }
  }

  const determineLastEmail = () => {
    if (user?.features?.newBilling) {
      const numOfUsers = accounts
        .find(acc => acc.contextId === modalAction.contextId)
        .users.filter(filterUser => filterUser.userStatus === 'Active').length
      setIsLastUser(numOfUsers < 2)
    }
    else {
      setIsLastUser(false)
    }
  }

  useEffect(() => {
    if (show) {
      determineLastEmail()
      openModal()
    }
  }, [show])

  return (
    <ModalProvider id="cloud-confirm-provider">
      <Modal
        closeBtnCb={onManualClose}
        containerProps={{ maxWidth: '650px' }}
        id="cloud-confirm-modal"
        isOpen={isOpen}
        onBgClick={onManualClose}
        onEscapeKey={onManualClose}
        width="auto"
      >
        <Stack width={['auto', '520px']}>
          <ConfirmationContent
            content={{
              ...content,
              ...(isLastUser &&
              modalAction.type === 'email' &&
              modalAction.action === 'delete'
                ? { warning: STRINGS.deleteUser.guard }
                : {}),
            }}
            mb={2}
            showTypeContent
            subheaderComponent={
              modalAction.action === 'delete' && (
                <Heading mb={2} variant="poundBold">
                  {content.subheader}
                </Heading>
              )
            }
            type={responseType}
          />

          <Box
            display={hideButtons ? 'none' : 'flex'}
            justifyContent="flex-end"
            mt={2}
          >
            <Button
              color={isInternic ? 'blueDark' : 'deepKoamaru'}
              mr={3}
              onClick={onManualClose}
              variant="inverse"
            >
              {STRINGS.no}
            </Button>
            <Button
              color={isInternic ? 'blueDark' : 'deepKoamaru'}
              onClick={handleConfirm}
              disabled={isLastUser && modalAction.type === 'email'}
            >
              {!showLoadingSpinner && STRINGS.yes}
              {showLoadingSpinner && (
                <Stack style={{ overflow: 'hidden' }}>
                  <Box flexDirection="column">
                    <Spinner color="white" size="1.5rem" stroke=".3rem" />
                  </Box>
                </Stack>
              )}
            </Button>
          </Box>
        </Stack>
      </Modal>
    </ModalProvider>
  )
}

OXDeleteRecoverModal.propTypes = propTypes
OXDeleteRecoverModal.defaultProps = defaultProps

export default OXDeleteRecoverModal
