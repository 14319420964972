import React, { useEffect, useState } from 'react'
import {
  Stack,
  Button,
  Text,
  Heading,
  Box,
  Input,
  Spinner,
  PasswordStrengthBar,
  Skeleton,
} from '@rebeldotcom/components'
import { isInternic, Dropdown, post, UserAuth, tracker } from '@rebeldotcom/ui'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { getOXBuyEmailEndpoints } from '../../../utilities/endpoints'
import {
  ANALYTICS_EVENTS,
  USERNAME_REGEX,
} from '../../my-account/email-manager/ox-email/ox.strings'

export const STRINGS = {
  existingDropdownTitle:
    'Welcome back! Which domain would you like to connect?',
  existingDropdownPlaceholder: 'Choose domain...',
  existingCheckout: 'Select Domain',
  addToCart: 'Add to Cart and Checkout',
  searchForDomain: 'Search for a different domain',
  selectDifferentDomain: 'Select a different domain',
  accountName: 'Account Name',
  password: 'Password',
  defaultErrorMessage:
    'Unable to purchase email. Please contact our Customer Support team for assistance.',
  TLDMessage: searchLimitedTo =>
    searchLimitedTo.length > 1
      ? `Your TLD must be one of the following ${searchLimitedTo.join(', ')}.`
      : `Your TLD must be a .${searchLimitedTo[0]} domain.`,
  addEmailTitle: 'Choose your custom email address',
  genericError: 'Something went wrong!',
  newDomainHeader: 'Add a new domain to continue:',
  newDomainAvailable: (domain, price) => `${domain} is available for ${price}`,
  newDomainUnavailable: 'This domain is not available.',
  newDomainSearchError: 'The search keyword needs to be a valid domain name.',
  loading: 'Loading domains...',
}

const btnColor = isInternic ? 'greenDark' : 'magenta'

const propTypes = {
  domains: PropTypes.array.isRequired,
  domainsLoading: PropTypes.bool,
  selectedPackage: PropTypes.shape({
    addToCartPath: PropTypes.string,
    amount: PropTypes.number,
    clickHerePath: PropTypes.string,
    id: PropTypes.string,
    itemCode: PropTypes.string,
    itemDescription: PropTypes.string,
    packageId: PropTypes.number,
    product: PropTypes.string,
    productCategory: PropTypes.string,
    productType: PropTypes.number,
    returnPath: PropTypes.string,
    source: PropTypes.string,
  }),
  user: PropTypes.object.isRequired,
}

const defaultProps = {
  domainsLoading: false,
  selectedPackage: {},
}

const ExistingDomain = ({ selectedPackage, domains, user, domainsLoading }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [domainSelected, setDomainSelected] = useState(false)
  const [disableButton, setDisabled] = useState(true)
  const [score, setScore] = useState(0)

  const [selectedDomain, setDomain] = useState()

  const validate = values => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Required'
    }

    if (values.username && !USERNAME_REGEX.test(values.username)) {
      errors.username = 'Invalid username'
    }

    if (!values.formikPassword) {
      errors.formikPassword = 'Required'
    }
    return errors
  }

  const onEmailSubmit = async values => {
    setLoading(true)
    const { firstname: firstName, lastname: lastName } = user

    const payload = {
      packageId: selectedPackage.packageId,
      users: 1,
      firstName,
      lastName,
      domainName: selectedDomain.value,
      password: values.formikPassword,
      ...values,
    }

    tracker(ANALYTICS_EVENTS.cloudEmail.event, {
      type: ANALYTICS_EVENTS.cloudEmail.types.configurator,
      cloudEmail: {
        itemCode: selectedPackage.itemCode,
        email: `${values.username}@${selectedDomain.value}`,
        domain: selectedDomain.value,
        username: values.username,
      },
    })

    try {
      const response = await post(getOXBuyEmailEndpoints(), payload)
      if (response.data.success) {
        window.location = `/my-account/email-manager/${response.data.result.contextId}/dns-setup`
      }
    } catch (err) {
      setError(err?.response?.data?.Message ?? STRINGS.defaultErrorMessage)
      setLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      formikPassword: '',
      username: '',
    },
    validate,
    onSubmit: values => {
      onEmailSubmit(values)
    },
  })
  const handleSubmit = e => {
    e.preventDefault()
    formik.handleSubmit(formik.values)
  }

  useEffect(() => {
    if (score < 3 || !formik.values.username) {
      setDisabled(true)
    } else if (score >= 3 && formik.values.username) {
      setDisabled(false)
    }
  }, [score, formik.values.username])

  return (
    <Stack bg="white" color="black" px={4} py={4} width="100%">
      {user && (
        <Heading mb={4} variant="giga">
          {domainSelected
            ? STRINGS.addEmailTitle
            : STRINGS.existingDropdownTitle}
        </Heading>
      )}
      {!user && (
        <Stack
          bg="white"
          color="black"
          maxWidth="containers.md"
          pb={3}
          width="100%"
        >
          <UserAuth id="account" showHeading stopRedirect />
        </Stack>
      )}
      {!domainSelected && !!user && (
        <>
          {domainsLoading ? (
            <Skeleton minHeight="60px" />
          ) : (
            <>
              <Dropdown
                id="select-domain-dropdown"
                onChange={setDomain}
                options={domains}
                width="100%"
              />
              <Button
                color={btnColor}
                id="add-existing-domain-to-cart"
                my={3}
                onClick={() => setDomainSelected(selectedDomain)}
              >
                <Text variant="mega">{STRINGS.existingCheckout}</Text>
              </Button>
            </>
          )}
        </>
      )}
      {domainSelected && user && (
        <>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box flexDirection="column" mb={3} mt={3} width="100%">
              <Text variant="milliBold">{STRINGS.accountName}</Text>
              <Box>
                <Input
                  id="account-name-prefix"
                  type="text"
                  {...formik.getFieldProps('username')}
                  autoComplete="off"
                />
                <Input
                  disabled
                  id="disabled-domain-name"
                  value={`@${selectedDomain.value}`}
                />
              </Box>
              {formik.touched.username && formik.errors.username ? (
                <Text color="red" mt={1} variant="milliBold">
                  {formik.errors.username}
                </Text>
              ) : null}
            </Box>
            <Box flexDirection="column" width="100%">
              <Text variant="milliBold"> {STRINGS.password} </Text>
              <Box>
                <Input
                  id="password"
                  type="password"
                  width="100%"
                  {...formik.getFieldProps('formikPassword')}
                  autoComplete="new-password"
                />
              </Box>
              <Box mt={2}>
                <PasswordStrengthBar
                  borderRadius="5px"
                  onChangeScore={s => setScore(s)}
                  password={formik.values.formikPassword}
                  showFeedback
                  showLabels
                />
              </Box>
              {formik.touched.formikPassword && formik.errors.formikPassword ? (
                <Box>
                  <Text color="red" maxWidth="400px" variant="milliBold">
                    {formik.errors.formikPassword}
                  </Text>
                </Box>
              ) : null}
            </Box>
            <Box alignItems="center" flexDirection="column" mt={2}>
              <Button
                color="magenta"
                disabled={disableButton}
                p={3}
                type="submit"
                width="60%"
              >
                {loading ? (
                  <Stack style={{ overflow: 'hidden' }}>
                    <Box flexDirection="column" m={2}>
                      <Spinner size="2rem" stroke=".3rem" />
                    </Box>
                  </Stack>
                ) : (
                  'Add Email'
                )}
              </Button>
              <Button
                id="search-again"
                mt={3}
                onClick={() => setDomainSelected(false)}
                variant="link"
              >
                <Text variant="mega">{STRINGS.selectDifferentDomain}</Text>
              </Button>
            </Box>
          </form>
          {error && (
            <Box mt={1}>
              <Text color="red" textAlign="center" variant="milliBold">
                {error}
              </Text>
            </Box>
          )}
        </>
      )}
    </Stack>
  )
}

ExistingDomain.propTypes = propTypes
ExistingDomain.defaultProps = defaultProps
export { ExistingDomain }
